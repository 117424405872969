import { Pipe, PipeTransform } from '@angular/core';
import { ProjectResponseGetById } from '@nx-customer-apps/api-planning-projects';

@Pipe({
    name: 'hasPartner'
})
export class HasPartnerPipe implements PipeTransform {
    transform(project: ProjectResponseGetById): boolean {
        return !!project.building.installer?.partnerNumber;
    }
}
