import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AuthActions } from './auth.actions';
import { Observable } from 'rxjs';
import { AuthSelectors } from './auth.selectors';
import { AppState, AuthState, UserResult } from '@nx-customer-apps/shared/interfaces';

@Injectable({ providedIn: 'root' })
export class AuthStore {
    public state$ = this.store$.pipe(select(AuthSelectors.getState())) as Observable<AuthState>;
    public authenticated$ = this.store$.pipe(select(AuthSelectors.getAuthenticatedState())) as Observable<boolean>;
    public authenticating$ = this.store$.pipe(select(AuthSelectors.getAuthenticating())) as Observable<boolean>;
    public canRedirect$ = this.store$.pipe(select(AuthSelectors.getCanRedirect())) as Observable<boolean>;
    public me$ = this.store$.pipe(select(AuthSelectors.getMe())) as Observable<UserResult>;
    public userId$ = this.store$.pipe(select(AuthSelectors.getUserId())) as Observable<string>;
    public userName$ = this.store$.pipe(select(AuthSelectors.getUserName())) as Observable<string>;
    public isViplanDefault$ = this.store$.pipe(select(AuthSelectors.isViplanDefault()));
    public countryLanguagePairs$ = this.store$.pipe(select(AuthSelectors.getCountryLanguagePairs()));
    public isInstaller$ = this.store$.pipe(select(AuthSelectors.isInstaller()));

    constructor(private store$: Store<AppState>) {}

    public startAuthFlow(): void {
        this.store$.dispatch(AuthActions.startAuthFlow());
    }

    public logout(): void {
        this.store$.dispatch(AuthActions.logout());
    }

    public clearToken(): void {
        this.store$.dispatch(AuthActions.clearToken());
    }
}
