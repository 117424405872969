import { Injectable } from '@angular/core';
import { FormName, GEGSystemType } from '@nx-customer-apps/shared/enums';
import { PrefillState } from '@nx-customer-apps/shared/interfaces';
import { Observable, Subject } from 'rxjs';

interface GegPrefillState {
    [FormName.System]: PrefillState;
    [GEGSystemType.Current]: {
        [FormName.EnergySource]: PrefillState;
        [FormName.HeatGeneratorDetails]: PrefillState;
    };
    [GEGSystemType.New]: {
        [FormName.EnergySource]: PrefillState;
    };
}

@Injectable({
    providedIn: 'root'
})
export class GegPrefillService {
    public state$: Observable<GegPrefillState>;
    private _state$: Subject<GegPrefillState> = new Subject();
    private state: GegPrefillState;

    constructor() {
        this.state$ = this._state$.asObservable();
    }

    public init(): void {
        this.state = {
            [FormName.System]: { isPrefilled: false, isFinished: false },
            [GEGSystemType.Current]: {
                [FormName.EnergySource]: { isPrefilled: false, isFinished: false },
                [FormName.HeatGeneratorDetails]: { isPrefilled: false, isFinished: false }
            },
            [GEGSystemType.New]: {
                [FormName.EnergySource]: { isPrefilled: false, isFinished: false }
            }
        };
    }

    public setAsPrefilled(formName: FormName, gegSystemType?: GEGSystemType.Current | GEGSystemType.New): void {
        if (this.state.hasOwnProperty(formName) && !gegSystemType) {
            (this.state as any)[formName].isPrefilled = true;
            (this.state as any)[formName].isFinished = true;
            this._state$.next(this.state);
            return;
        }

        if (!gegSystemType) {
            return;
        }

        if (this.state.hasOwnProperty(gegSystemType) && this.state[gegSystemType].hasOwnProperty(formName)) {
            (this.state as any)[gegSystemType][formName].isPrefilled = true;
            (this.state as any)[gegSystemType][formName].isFinished = true;
            this._state$.next(this.state);
        }
    }

    public isPrefilled(formName: FormName, gegSystemType?: GEGSystemType.Current | GEGSystemType.New): boolean {
        return gegSystemType ? (this.state as any)[gegSystemType][formName].isPrefilled : (this.state as any)[formName].isPrefilled;
    }

    public setAsFinished(formName: FormName, gegSystemType?: GEGSystemType.Current | GEGSystemType.New): void {
        if (this.state.hasOwnProperty(formName) && !gegSystemType) {
            (this.state as any)[formName].isFinished = true;
            this._state$.next(this.state);
            return;
        }

        if (!gegSystemType) {
            return;
        }

        if (this.state.hasOwnProperty(gegSystemType) && this.state[gegSystemType].hasOwnProperty(formName)) {
            (this.state as any)[gegSystemType][formName].isFinished = true;
            this._state$.next(this.state);
        }
    }

    public isFinished(formName: FormName, gegSystemType?: GEGSystemType.Current | GEGSystemType.New): boolean {
        return gegSystemType ? (this.state as any)[gegSystemType][formName].isFinished : (this.state as any)[formName].isFinished;
    }
}
