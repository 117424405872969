import { Pipe, PipeTransform } from '@angular/core';
import { ProjectResponseGetById } from '@nx-customer-apps/api-planning-projects';
import { CountryCode } from '@nx-customer-apps/shared/enums';
import { isOfCountry } from '@nx-customer-apps/shared/utils';

@Pipe({
    name: 'isOfCountry'
})
export class IsOfCountryPipe implements PipeTransform {
    transform(project: ProjectResponseGetById, countryCode: CountryCode): boolean {
        return isOfCountry(project, countryCode);
    }
}
