import { createSelector } from '@ngrx/store';
import { AppState, ProjectsState } from '@nx-customer-apps/shared/interfaces';

export namespace ProjectsSelectors {
    const projectsState = (state: AppState) => state.projects;

    export const getState = () => createSelector(projectsState, (state: ProjectsState) => state);

    export const getProjectFormValue = () => createSelector(projectsState, (state: ProjectsState) => state.projectFormValue);

    export const getFiltersValue = () =>
        createSelector(projectsState, (state: ProjectsState) => ({
            filter: state?.queryParams?.filter,
            projectStatus: state?.queryParams?.projectStatus,
            lastChangeDateFrom: state?.queryParams?.lastChangeDateFrom,
            lastChangeDateTo: state?.queryParams?.lastChangeDateTo,
            allProjects: state?.queryParams?.allProjects
        }));

    export const getHasFiltersDefined = () =>
        createSelector(
            projectsState,
            (state: ProjectsState) =>
                !!state?.queryParams?.filter ||
                !!state?.queryParams?.projectStatus ||
                !!state?.queryParams?.lastChangeDateFrom ||
                !!state?.queryParams?.lastChangeDateTo ||
                !!state?.queryParams?.allProjects
        );

    export const getOrderValue = () =>
        createSelector(projectsState, (state: ProjectsState) => ({
            orderBy: state?.queryParams?.orderBy,
            orderType: state?.queryParams?.orderType
        }));
}
