import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CheckboxQuestion, CheckboxSet, QuestionGroupBase } from '@nx-customer-apps/shared/utils';
import { slideDown } from '../../animations';

@Component({
    selector: 'vp-checkbox-set',
    templateUrl: './checkbox-set.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [slideDown]
})
export class CheckboxSetComponent {
    @Input() question: CheckboxSet;
    @Input() form: FormGroup;

    public get formGroup(): FormGroup {
        return this.form.get(this.question.groupName) as FormGroup;
    }

    public get errorMessage(): string {
        const errorMessage = this.formGroup?.errors?.['message'];
        return errorMessage || (this.question as QuestionGroupBase<CheckboxQuestion[]>).defaultErrorMessage;
    }

    get isInvalid(): boolean {
        return this.formGroup?.invalid;
    }
    get isTouched(): boolean {
        return this.formGroup?.touched;
    }
}
