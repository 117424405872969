import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    AppState,
    BuildingFormValue,
    Device,
    DistributionAndScheduleFormValue,
    EnergyConsumptionFormValue,
    HeatGeneratorDetailsFormValue,
    HeatGeneratorFormValue,
    LivingAreaFormValue,
    SameFormValue,
    SubsidyEligibility
} from '@nx-customer-apps/shared/interfaces';
import { HeatPumpIntermediaryActions } from './heat-pump-intermediary.actions';
import { HeatPumpIntermediarySelectors } from './heat-pump-intermediary.selectors';

@Injectable({ providedIn: 'root' })
export class HeatPumpIntermediaryStore {
    public state$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.getState));
    public isOldDeviceUsed$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.isOldDeviceUsed$));
    public subsidyEligibility$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.subsidyEligibility$));
    public livingArea$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.livingArea));
    public heatGenerators$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.heatGenerators$));
    public heatGeneratorTypes$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.heatGeneratorTypes$));
    public consumptionProfiles$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.consumptionProfiles$));
    public selectedHeatingEnergySource$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.selectedHeatingEnergySource$));
    public building$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.building$));
    public heatGeneratorDetails$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.getHeatGeneratorDetails));
    public energyConsumption$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.getEnergyConsumption));
    public heatingDistribution$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.getHeatingDistribution));
    public heatGeneratorsAndOldDeviceUsage$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.heatGeneratorsAndOldDeviceUsage$));
    public heatingSystems$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.getHeatingSystems));
    public sameValueSubmited$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.sameValueSubmited));
    public heatGeneratorsAndConsumptionProfilesAndOldDeviceUsage$ = this.store$.pipe(
        select(HeatPumpIntermediarySelectors.heatGeneratorsAndConsumptionProfilesAndOldDeviceUsage$)
    );
    public cache$ = this.store$.pipe(select(HeatPumpIntermediarySelectors.cache));

    constructor(private store$: Store<AppState>) {}

    public submitDevice(payload: Device): void {
        this.store$.dispatch(HeatPumpIntermediaryActions.submitDevice(payload));
    }

    public submitBuilding(payload: BuildingFormValue): void {
        this.store$.dispatch(HeatPumpIntermediaryActions.submitBuilding(payload));
    }

    public submitHeatGenerator(payload: HeatGeneratorFormValue): void {
        this.store$.dispatch(HeatPumpIntermediaryActions.submitHeatGenerator(payload));
    }

    public submitHeatGeneratorDetails(payload: HeatGeneratorDetailsFormValue): void {
        this.store$.dispatch(HeatPumpIntermediaryActions.submitHeatGeneratorDetails(payload));
    }

    public submitEnergyConsumption(payload: EnergyConsumptionFormValue): void {
        this.store$.dispatch(HeatPumpIntermediaryActions.submitEnergyConsumption(payload));
    }

    public submitHeatingDistribution(payload: DistributionAndScheduleFormValue): void {
        this.store$.dispatch(HeatPumpIntermediaryActions.submitHeatingDistribution(payload));
    }

    public submitSubsidyEligibility(payload: SubsidyEligibility): void {
        this.store$.dispatch(HeatPumpIntermediaryActions.submitSubsidyEligibility(payload));
    }

    public submitLivingArea(payload: LivingAreaFormValue): void {
        this.store$.dispatch(HeatPumpIntermediaryActions.submitLivingArea(payload));
    }

    public getHeatingSystems(installationYear: string): void {
        this.store$.dispatch(HeatPumpIntermediaryActions.getHeatingSystems({ installationYear }));
    }

    public submitSameValue(form: SameFormValue): void {
        this.store$.dispatch(HeatPumpIntermediaryActions.submitSameValue(form));
    }

    public resetSameValue(): void {
        this.store$.dispatch(HeatPumpIntermediaryActions.resetSameValue());
    }

    public resetHeatPumpIntermediary(): void {
        this.store$.dispatch(HeatPumpIntermediaryActions.resetHeatPumpIntermediary());
    }
}
