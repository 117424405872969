import { Injectable } from '@angular/core';
import { map, Observable, withLatestFrom, filter, first, merge } from 'rxjs';
import { HeatloadPrefillService } from './heatload-prefill.service';
import { HeatloadFormService } from './heatload-form.service';
import { CountryCode, FormName, ProjectType } from '@nx-customer-apps/shared/enums';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { Question } from '@nx-customer-apps/shared/utils';
import { ProjectStore } from '../../state/project/project.store';
import { HeatloadPrefillMapper, ProjectsService } from '../../services';
import { EnergyStandard } from '@nx-customer-apps/api-planning-projects';
import { TranslateService } from '@ngx-translate/core';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { RollOutPolicy } from '../../policies';
import { HeatloadService } from './heatload.service';
import { AccordionService } from '../../shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class EnergyStandardService implements AccordionService {
    constructor(
        private heatloadPrefillService: HeatloadPrefillService,
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadFormService: HeatloadFormService,
        private heatloadService: HeatloadService,
        private heatloadStore: HeatloadStore,
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private projectStore: ProjectStore,
        private projectsService: ProjectsService,
        private rollOutPolicy: RollOutPolicy,
        private translate: TranslateService
    ) {}

    public questions$: Observable<Question[] | null> = this.heatloadStore.energyStandards$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$),
        map(([energyStandards, project, cache]) => ({
            energyStandards,
            project,
            cache
        })),
        map(state => {
            if (!state.energyStandards) {
                return null;
            }
            const cachePrefill = this.prefillMapper.stateToEnergyStandardPrefill(state.cache?.selectedEnergyStandard);
            const projectPrefill = this.prefillMapper.projectToEnergyStandardPrefill(state.project, state.energyStandards);
            const formOptions = this.heatloadFormOptionsService.getEnergyStandardFormOptions({
                prefill: cachePrefill || projectPrefill,
                energyStandards: state.energyStandards
            });
            return this.heatloadFormService.getEnergyStandardQuestions(formOptions);
        })
    );

    public getSuccessMessage(value: EnergyStandard): string {
        return this.translate.instant(`COMMON.ENERGY_STANDARDS.${value.countryCode}.${value.key}`);
    }

    public isPrefillNeeded(): boolean {
        return this.heatloadService.isRecalculation() && !this.heatloadPrefillService.isPrefilled(FormName.EnergyStandard);
    }

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.EnergyStandard);
    }

    public isExpanded$ = merge(this.prefillCompletedByLivingArea$(), this.isSameValueSubmitedLivingArea$());

    public title$: Observable<string | undefined> = this.projectStore.project$.pipe(
        map(project => {
            const countryCode = project?.address.countryCode as CountryCode;
            return this.rollOutPolicy.getEnergyStandardTitle(countryCode);
        })
    );

    public description$: Observable<string | undefined> = this.projectStore.project$.pipe(
        map(project => {
            const countryCode = project?.address.countryCode as CountryCode;
            return this.rollOutPolicy.getEnergyStandardDescrition(countryCode);
        })
    );

    private isSameValueSubmitedLivingArea$(): Observable<boolean> {
        return this.heatloadStore.sameValueSubmited$.pipe(
            withLatestFrom(this.projectStore.project$, (form, project) => ({ form, project })),
            map(
                ({ form, project }) =>
                    form?.formName === FormName.LivingArea && this.projectsService.isNewConstruction(project!.projectType as ProjectType)
            )
        );
    }

    private prefillCompletedByLivingArea$(): Observable<boolean> {
        return this.heatloadPrefillService.state$.pipe(
            withLatestFrom(this.projectStore.project$, (prefillState, project) => ({ prefillState, project })),
            map(({ prefillState, project }) => {
                const lastAccordionToBePrefilled = this.projectsService.isNewConstruction(project!.projectType as ProjectType)
                    ? FormName.EnergyStandard
                    : FormName.Ventilation;
                return prefillState[lastAccordionToBePrefilled].isPrefilled && !this.heatloadService.isRecalculation();
            }),
            filter(Boolean),
            first()
        );
    }
}
