import { Component, Inject, Input, OnInit, QueryList, Renderer2, RendererFactory2, ViewChild, ViewChildren } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    FormGroup,
    ValidationErrors,
    Validator
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { DropdownErrorMatcher, SearchDropdown, searchDropdownOptionsValidator } from '@nx-customer-apps/shared/utils';
import { ViewHostDirective } from '../../directives/view-host.directive';
import { debounceTime, delay, distinctUntilChanged, map, switchMap, tap } from 'rxjs';
import { slideDown } from '../../animations';
import { SearchDropdownItem } from '@nx-customer-apps/shared/interfaces';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'vp-search-dropdown',
    templateUrl: './search-dropdown.component.html',
    styleUrls: ['./search-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SearchDropdownComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: SearchDropdownComponent
        }
    ],
    animations: [slideDown]
})
export class SearchDropdownComponent implements ControlValueAccessor, Validator, OnInit {
    @Input() highlight: boolean;
    @Input() question: SearchDropdown;
    /**
     * The direct form group the question belongs to.
     */
    @Input() formGroup: FormGroup;
    @Input() minimalSearchValueLength = 3;
    @ViewChild('dropdownRef') dropdownRef: MatSelect;
    @ViewChildren(ViewHostDirective) optionHosts: QueryList<ViewHostDirective>;
    public dropdownControl = new FormControl();
    public textControl = new FormControl();
    public matcher = new DropdownErrorMatcher();
    public value: any;
    public loading: boolean;
    private renderer: Renderer2;

    constructor(@Inject(DOCUMENT) private document: Document, rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public ngOnInit(): void {
        this.openDropDown();
        this.initializeValue();
        this.setValidators(this.question.options);
    }

    public writeValue(value: any): void {
        this.value = value;
        if (!value) {
            this.textControl.setValue(value, { emitEvent: false });
        }
    }

    /* istanbul ignore next */
    public onChange: (...args: any) => void = (value: number | '') => {};

    /* istanbul ignore next */
    public registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    /* istanbul ignore next */
    public onTouched: (...args: any) => void = () => {};

    /* istanbul ignore next */
    public registerOnTouched(onTouched: (...args: any) => void): void {
        this.onTouched = onTouched;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return this.textControl.errors;
    }

    /* istanbul ignore next */
    public onValidationChange: (...args: any) => void = () => {};

    /* istanbul ignore next */
    public registerOnValidatorChange?(onValidationChange: (...args: any) => void): void {
        this.onValidationChange = onValidationChange;
    }

    public get isInvalid(): boolean {
        return this.control.invalid || this.textControl?.invalid;
    }

    public get control(): AbstractControl {
        return this.formGroup!.get(this.question.key)!;
    }

    public get isTouched(): boolean {
        return this.control.touched || this.textControl.touched;
    }

    public get errorMessage(): string {
        const errorMessage = this.textControl?.errors?.['message'];
        return errorMessage || this.question.defaultErrorMessage;
    }

    public openDropDown(): void {
        this.textControl.valueChanges
            .pipe(
                map(value => value.trim()),
                map(value => (value.length < this.minimalSearchValueLength ? '' : value)),
                debounceTime(300),
                distinctUntilChanged(),
                tap(value => {
                    if (value) {
                        this.tooglefBackdropClickEvents(true);
                        this.loading = true;
                        this.dropdownRef.open();
                    }
                }),
                switchMap(value => this.question.optionsUpdater(value)),
                tap(newOptions => {
                    this.question.options = newOptions.length ? newOptions : this.question.defaultOptions;
                    this.setValidators(newOptions);
                    this.textControl.updateValueAndValidity();
                    this.tooglefBackdropClickEvents(false);
                }),
                tap(() => {
                    this.loading = false;
                }),
                delay(0),
                tap(() => {
                    this.loadOptionTemplates();
                    this.dropdownRef.open();
                })
            )
            .subscribe();
    }

    private setValidators(options: SearchDropdownItem[]): void {
        const validators = [...this.question.validators, searchDropdownOptionsValidator(options, this.question.defaultErrorMessage)];
        this.textControl.setValidators(validators);
    }

    public onDropdownSelect(event: { value: any }): void {
        this.textControl.setValue(event.value, { emitEvent: false });
        const selectedOption = this.question.options.find(option => option.key === event.value)!;
        this.writeValue(selectedOption.value);
        this.onChange(selectedOption.value);
        this.dropdownControl.reset();
    }

    public onFocus(): void {
        if (this.textControl.value && this.question.options.length > 0) {
            this.loadOptionTemplates();
            this.dropdownRef.open();
        }
    }

    /**
     * Generates custom templates for dropdown options if they're defined in the this.question.options.
     */
    private loadOptionTemplates(): void {
        this.question.options.forEach((option, index) => {
            if (!option.customTemplate) {
                return;
            }
            const host = this.optionHosts.get(index);
            if (host) {
                host.viewContainerRef.clear();
                const componentRef = host.viewContainerRef.createComponent(option.customTemplate.component);
                if (componentRef) {
                    const componentInputs = option.customTemplate.inputs;
                    for (let key in componentInputs) {
                        (<any>componentRef).instance[key] = componentInputs[key];
                    }
                }
            }
        });
    }

    /**
     * Workaround! It prevents the dropdown from closing on "click outside" event while the options are being loaded [CAVP-1031]
     */
    private tooglefBackdropClickEvents(isActive: boolean): void {
        isActive
            ? this.renderer.addClass(this.document.body, 'vp-cdk-backdrop-pointer-events-none')
            : this.renderer.removeClass(this.document.body, 'vp-cdk-backdrop-pointer-events-none');
    }

    private initializeValue(): void {
        const selectedOption = this.question.options.find(option => option.value === this.question.value);
        this.textControl.setValue(selectedOption?.key, { emitEvent: false });
    }
}
