import { Injectable } from '@angular/core';
import { FormName } from '@nx-customer-apps/shared/enums';
import { PrefillState } from '@nx-customer-apps/shared/interfaces';
import { Observable, Subject } from 'rxjs';

interface HeatloadPrefillState {
    [FormName.EnergyCertificate]: PrefillState;
    [FormName.BuildingHull]: PrefillState;
    [FormName.RoofType]: PrefillState;
    [FormName.RoofDimensions]: PrefillState;
    [FormName.ModernizationsAndInsulation]: PrefillState;
    [FormName.WindowsAndGlazing]: PrefillState;
    [FormName.Modernizations]: PrefillState;
    [FormName.LivingArea]: PrefillState;
    [FormName.EnergyStandard]: PrefillState;
    [FormName.Ventilation]: PrefillState;
    [FormName.EnergySource]: PrefillState;
    [FormName.HeatGeneratorDetails]: PrefillState;
    [FormName.EnergyConsumption]: PrefillState;
    [FormName.HeatingDistribution]: PrefillState;
}

@Injectable({
    providedIn: 'root'
})
export class HeatloadPrefillService {
    public state$: Observable<HeatloadPrefillState>;
    private _state$: Subject<HeatloadPrefillState> = new Subject();
    private state: HeatloadPrefillState;

    constructor() {
        this.state$ = this._state$.asObservable();
    }

    public init(): void {
        this.state = {
            [FormName.EnergyCertificate]: { isPrefilled: false },
            [FormName.BuildingHull]: { isPrefilled: false },
            [FormName.RoofType]: { isPrefilled: false },
            [FormName.RoofDimensions]: { isPrefilled: false },
            [FormName.ModernizationsAndInsulation]: { isPrefilled: false },
            [FormName.WindowsAndGlazing]: { isPrefilled: false },
            [FormName.Modernizations]: { isPrefilled: false },
            [FormName.LivingArea]: { isPrefilled: false },
            [FormName.EnergyStandard]: { isPrefilled: false },
            [FormName.Ventilation]: { isPrefilled: false },
            [FormName.EnergySource]: { isPrefilled: false },
            [FormName.HeatGeneratorDetails]: { isPrefilled: false },
            [FormName.EnergyConsumption]: { isPrefilled: false },
            [FormName.HeatingDistribution]: { isPrefilled: false }
        };
    }

    public setAsPrefilled(formName: FormName): void {
        if (this.state?.hasOwnProperty(formName)) {
            (this.state as any)[formName].isPrefilled = true;
            this._state$.next(this.state);
        }
    }

    public isPrefilled(formName: FormName): boolean {
        return (this.state as any)?.[formName].isPrefilled;
    }
}
