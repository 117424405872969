import { Pipe, PipeTransform } from '@angular/core';
import { ProjectStatus } from '@nx-customer-apps/shared/enums';

@Pipe({
    name: 'projectStatusStyles'
})
export class ProjectStatusStylesPipe implements PipeTransform {
    transform(projectStatus: ProjectStatus): string {
        const styles = {
            [ProjectStatus.Design]: 'status-dark-grey',
            [ProjectStatus.PlanningDone]: 'status-yellow',
            [ProjectStatus.QuoteCreated]: 'status-blue',
            [ProjectStatus.OrderPlaced]: 'status-seledin',
            [ProjectStatus.Completed]: 'status-green',
            [ProjectStatus.Closed]: 'status-red',
            [ProjectStatus.Archived]: 'status-light-grey',
            [ProjectStatus.Migrated]: 'status-salmon',
            [ProjectStatus.MigratedClosed]: 'status-red'
        };
        return styles[projectStatus] || 'status-dark-grey';
    }
}
