import { GegEvaluationRequestPost, GegEvaluationResponse } from '@nx-customer-apps/api-planning-projects';
import { createAction, props } from '@ngrx/store';

export namespace GEGEvaluationActions {
    export const evaluateAlternativeGEG = createAction(
        '[GEG Evaluation] Evaluate Alternative GEG',
        props<{ body: GegEvaluationRequestPost }>()
    );

    export const evaluateAlternativeGEGSuccess = createAction(
        '[GEG Evaluation] Evaluate Alternative GEG Success',
        props<{ evaluation: GegEvaluationResponse }>()
    );

    export const resetAlternativeEvaluation = createAction('[GEG Evaluation] Reset Alternative Evaluation');

    export const evaluateReviewedGEG = createAction('[GEG Evaluation] Evaluate Reviewed GEG', props<{ body: GegEvaluationRequestPost }>());

    export const evaluateReviewedGEGSuccess = createAction(
        '[GEG Evaluation] Evaluate Reviewed GEG Success',
        props<{ evaluation: GegEvaluationResponse }>()
    );

    export const resetReviewedEvaluation = createAction('[GEG Evaluation] Reset Reviewed Evaluation');
}
