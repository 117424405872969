import { ControlTypes } from '@nx-customer-apps/shared/enums';
import { HintFunction, Mask, QuestionOptions } from '@nx-customer-apps/shared/interfaces';
import { QuestionBase } from './question-base.model';

export class TextboxQuestion extends QuestionBase<string | number> {
    override controlType = ControlTypes.Textbox;
    public mask: Mask;
    public placeholder: string;
    public suffix: string;
    public hints: HintFunction[];
    public paragraph: string;
    public permanentHint: string;
    public ifConditionalHint: boolean;
    public conditionalHint: string;
    constructor(options: QuestionOptions<string | number>) {
        super(options);
        this.paragraph = options.paragraph || '';
        this.placeholder = options.placeholder || '';
        this.mask = TextboxQuestion.setMask(options.mask);
        this.suffix = options.suffix || '';
        this.hints = options.hints || [];
        this.permanentHint = options.permanentHint || '';
        this.ifConditionalHint = !!options.ifConditionalHint;
        this.conditionalHint = options.conditionalHint || '';
    }

    static setMask(mask: Mask = { pattern: '' } as Mask): Mask {
        return {
            separatorLimit: '',
            ...mask
        };
    }
}
