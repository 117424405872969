import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, filter, map, tap } from 'rxjs';
import { ProjectStore } from '../state/project/project.store';
import { isReadOnly } from '@nx-customer-apps/shared/utils';
import { ProjectStatus, RoutePaths, RouterLinks } from '@nx-customer-apps/shared/enums';

@Injectable({
    providedIn: 'root'
})
export class IsEditableGuardService {
    constructor(private router: Router, private projectStore: ProjectStore) {}
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.projectStore.project$.pipe(
            tap(project => {
                const projectId = route.paramMap.get('id');

                if (!projectId) {
                    this.router.navigate([RoutePaths.Error404]);
                }

                if (!project) {
                    this.projectStore.getProject({ id: projectId! });
                }
            }),
            filter(Boolean),
            /**
             * It's allowed to navigate to verify pages (via "Continue to complete") link if project is migrated
             * even if whole project is read-only.
             *
             * Please note that access to project detatils subviews is always allowed only via links because of locking mechanism.
             */
            tap(project => {
                if (isReadOnly(project.projectStatus as ProjectStatus, { excludeMigrated: true })) {
                    this.router.navigate([`${RouterLinks.Project}${project.id}`]);
                }
            }),
            map(project => !isReadOnly(project.projectStatus as ProjectStatus, { excludeMigrated: true }))
        );
    }
}
