import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { LodashService } from '@nx-customer-apps/shared/services';

/**
 * Checks the width of the element and sets disabled property of matTooltip directive which is put on that element.
 */
@Directive({
    selector: '[vpWidthTooltip]'
})
export class WidthTooltipDirective {
    @Input('minElementWidth') minWidth: number;
    @Input('maxElementWidth') maxWidth: number;

    public ngAfterViewInit() {
        this.checkElementWidth();
    }

    @HostListener('window:resize', ['$event']) onWindowResize() {
        this.checkElementWidth();
    }

    private checkElementWidth() {
        if (this.hasIncorrectValues()) {
            throw new Error(
                'minElementWidth connot be higher than or equal maxElementWidth, maxElementWidth connot be lower than or equal minElementWidth'
            );
        }

        if (!LodashService.isNil(this.maxWidth)) {
            this.onMaxWidth();
        }

        if (!LodashService.isNil(this.minWidth)) {
            this.onMinWidth();
        }
        this.changeDetectorRef.detectChanges();
    }

    constructor(private element: ElementRef, private tooltip: MatTooltip, private changeDetectorRef: ChangeDetectorRef) {}

    private hasIncorrectValues(): boolean {
        if (LodashService.isNil(this.minWidth)) {
            return false;
        }
        if (LodashService.isNil(this.maxWidth)) {
            return false;
        }

        return this.minWidth >= this.maxWidth;
    }

    private onMaxWidth() {
        const isTooLong = this.element.nativeElement.offsetWidth > this.maxWidth;
        this.tooltip.disabled = isTooLong;
    }

    private onMinWidth() {
        const isTooShort = this.element.nativeElement.offsetWidth < this.minWidth;
        this.tooltip.disabled = isTooShort;
    }
}
