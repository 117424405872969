import { ChangeDetectionStrategy, EventEmitter, Output, Component, Input } from '@angular/core';
import { AnchorIds } from '@nx-customer-apps/shared/enums';
import { NavbarAnchor } from '@nx-customer-apps/shared/interfaces';

@Component({
    selector: 'vp-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {
    @Input() anchors: NavbarAnchor[] = [];
    @Input() activeAnchorId: AnchorIds | null;
    @Output() anchorClick: EventEmitter<NavbarAnchor> = new EventEmitter();
}
