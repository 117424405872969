import { Injectable } from '@angular/core';
import { filter, map, withLatestFrom } from 'rxjs';
import { ProjectStore } from '../../state/project/project.store';
import { HeatPumpIntermediaryFormService } from './heat-pump-intermediary-form.service';
import { ProjectsService } from '../../services';
import { FormKeys, FormName, HeatPumpIntermediarySuccessMessages, ProjectType } from '@nx-customer-apps/shared/enums';
import { HeatPumpIntermediaryFormOptionsService } from './heat-pump-intermediary-form-options.service';
import { HeatPumpIntermediaryPrefillService } from './heat-pump-intermediary-prefill.service';
import { HeatPumpIntermediaryPrefillMapper } from './heat-pump-intermediary-prefill.mapper';
import { HeatPumpIntermediaryStore } from '../../state/heat-pump-intermediary/heat-pump-intermediary.store';
import { AccordionService } from '../../shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class HeatPumpIntermediaryDeviceService implements AccordionService {
    constructor(
        private projectStore: ProjectStore,
        private formService: HeatPumpIntermediaryFormService,
        private formOptionsService: HeatPumpIntermediaryFormOptionsService,
        private prefillService: HeatPumpIntermediaryPrefillService,
        private prefillMapper: HeatPumpIntermediaryPrefillMapper,
        private heatPumpIntermediaryStore: HeatPumpIntermediaryStore,
        private projectsService: ProjectsService
    ) {}

    public questions$ = this.projectStore.project$.pipe(
        withLatestFrom(this.heatPumpIntermediaryStore.cache$, (project, cache) => ({ project, cache })),
        filter(({ project }) => this.projectsService.isRenovation(project!.projectType as ProjectType)),
        map(({ project, cache }) => {
            const cachePrefill = this.prefillMapper.stateToDevicePrefill(cache?.isOldDeviceUsed);
            const projectPrefill = this.prefillMapper.projectToDevicePrefill(project);
            const options = this.formOptionsService.getDeviceFormOptions({ prefill: cachePrefill || projectPrefill });
            return this.formService.getDeviceQuestions(options);
        })
    );

    public getSuccessMessage(formValue: { [FormKeys.IsOldDeviceUsed]: boolean }): string {
        return formValue[FormKeys.IsOldDeviceUsed] ? HeatPumpIntermediarySuccessMessages.Yes : HeatPumpIntermediarySuccessMessages.No;
    }

    public isPrefillNeeded(): boolean {
        return !this.prefillService.isPrefilled(FormName.Device);
    }

    public afterPrefill(): void {
        this.prefillService.setAsPrefilled(FormName.Device);
    }
}
