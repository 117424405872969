import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { GlobalSelectors } from './global.selectors';
import { AppState } from '@nx-customer-apps/shared/interfaces';

@Injectable({ providedIn: 'root' })
export class GlobalStore {
    public state$ = this.store$.pipe(select(GlobalSelectors.getGlobalState()));

    constructor(private store$: Store<AppState>) {}
}
