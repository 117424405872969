import { ControlTypes } from '@nx-customer-apps/shared/enums';
import { QuestionGroupOptions } from '@nx-customer-apps/shared/interfaces';
import { QuestionGroupBase } from './question-group-base.model';
import { AddressSubquestion } from '../types';

export class Addressbox extends QuestionGroupBase<AddressSubquestion[]> {
    override controlType = ControlTypes.Addressbox;
    constructor(options: QuestionGroupOptions<AddressSubquestion[]>) {
        super(options);
    }
}
