import { createSelector } from '@ngrx/store';
import { AppState } from '@nx-customer-apps/shared/interfaces';
import { GEGSystemType } from '@nx-customer-apps/shared/enums';
import { GegEvaluationRequestPost, GegSystemPost } from '@nx-customer-apps/api-planning-projects';

export namespace GEGSelectors {
    export const GEGState = (state: AppState) => state.GEG;

    export const getState = () => createSelector(GEGState, state => state);

    export const getSystem = () => createSelector(GEGState, state => state.system);

    export const getHeatGenerators = () => createSelector(GEGState, state => state.heatGenerators);

    export const getCurrentSystemHeatGeneratorDetails = () => createSelector(GEGState, state => state.currentSystem?.heatGeneratorDetails);

    export const getCurrentSystemEnergySource = () => createSelector(GEGState, state => state.currentSystem?.energySource);

    export const getNewSystemEnergySource = () => createSelector(GEGState, state => state.newSystem?.energySource);

    export const getCurrentSystem = () => createSelector(GEGState, state => state.currentSystem);

    export const getCurrentSystemHeatingSystems = () => createSelector(GEGState, state => state.currentSystem?.heatingSystems);

    export const getNewSystem = () => createSelector(GEGState, state => state.newSystem);

    export const sameValueSubmited = () => createSelector(GEGState, state => state.sameValueSubmited);

    export const cache = () => createSelector(GEGState, state => state.cache);

    export const getGEGEvaluationRequest = () =>
        createSelector(GEGState, state => {
            if (!state.system) {
                return null;
            }
            const type = state.system!.value;
            const newSystem = {
                energySource: state.newSystem?.energySource?.selectedEnergySource.type,
                heatGeneratorType: state.newSystem?.energySource?.selectedHeatGenerator,
                heatingSystemType: state.newSystem?.heatGeneratorDetails?.boilerType,
                yearOfConstruction: +state.newSystem?.heatGeneratorDetails?.installationYear!,
                heatPumpOperatingMode: state.newSystem?.heatGeneratorDetails?.heatPumpOperatingMode,
                isLowTemperature: state.newSystem?.heatGeneratorDetails?.lowTemperature
            } as GegSystemPost;
            const currentSystem = {
                energySource: state.currentSystem?.energySource?.selectedEnergySource.type,
                heatGeneratorPower: state.currentSystem?.heatGeneratorDetails?.nominalPower,
                heatGeneratorType: state.currentSystem?.energySource?.selectedHeatGenerator,
                heatingSystemType: state.currentSystem?.heatGeneratorDetails?.boilerType,
                heatPumpOperatingMode: state.currentSystem?.heatGeneratorDetails?.heatPumpOperatingMode,
                isLowTemperature: state.currentSystem?.heatGeneratorDetails?.lowTemperature,
                yearOfConstruction: +state.currentSystem?.heatGeneratorDetails?.installationYear!
            } as GegSystemPost;

            let body: GegEvaluationRequestPost;
            if (type === GEGSystemType.New) {
                body = { type, newSystem };
            } else if (type === GEGSystemType.Current) {
                body = {
                    type,
                    currentSystem
                };
            } else {
                body = {
                    type,
                    currentSystem,
                    newSystem
                };
            }
            return { body };
        });

    export const getGEGEvaluationResponse = () => createSelector(GEGState, state => state.evaluation);
}
