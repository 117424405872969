import { Injectable } from '@angular/core';
import { GegEvaluationScreenService } from './geg-evaluation-screen.service';
import { Router } from '@angular/router';
import { GEGStore } from '../../state/geg/geg.store';
import { take, tap, withLatestFrom } from 'rxjs';
import { GEGSystemType, RoutePaths, RouterLinks } from '@nx-customer-apps/shared/enums';
import { ProjectStore } from '../../state/project/project.store';

@Injectable()
export class GegEvaluatedSystemService extends GegEvaluationScreenService {
    constructor(private router: Router, private gegStore: GEGStore, private projectStore: ProjectStore) {
        super();
    }

    public navigateBack(): void {
        this.gegStore.system$
            .pipe(
                take(1),
                withLatestFrom(this.projectStore.project$, (system, project) => ({ system: system?.value, project })),
                tap(({ system, project }) => {
                    const routeOptions = {
                        [GEGSystemType.Current]: RoutePaths.CurrentSystem,
                        [GEGSystemType.New]: RoutePaths.NewSystem,
                        [GEGSystemType.Hybrid]: RoutePaths.AddNewSystem
                    };
                    this.router.navigate([RouterLinks.GEG, project!.id, routeOptions[system!]]);
                })
            )
            .subscribe();
    }
}
