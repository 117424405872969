import { Injectable } from '@angular/core';
import { withLatestFrom, map, Observable, filter, first, merge } from 'rxjs';
import { HeatloadPrefillService } from './heatload-prefill.service';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { HeatloadFormService } from './heatload-form.service';
import { TranslateService } from '@ngx-translate/core';
import { WindowsAndGlazingFormValue } from '@nx-customer-apps/shared/interfaces';
import { FormName } from '@nx-customer-apps/shared/enums';
import { windowPercentageAreaToPhraseKeyDictionary, windowTypeToPhrase } from '@nx-customer-apps/shared/utils';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { ProjectStore } from '../../state/project/project.store';
import { ActivatedRoute } from '@angular/router';
import { HeatloadPrefillMapper } from '../../services';
import { HeatloadService } from './heatload.service';
import { AccordionService } from '../../shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class WindowsAndGlazingService implements AccordionService {
    constructor(
        private heatloadPrefillService: HeatloadPrefillService,
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadStore: HeatloadStore,
        private heatloadService: HeatloadService,
        private heatloadFormService: HeatloadFormService,
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private projectStore: ProjectStore,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {}

    public questions$ = this.heatloadStore.windows$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$),
        map(([windows, project, cache]) => ({
            windows,
            project,
            cache
        })),
        map(state => {
            if (!state.windows) {
                return null;
            }
            const projectPrefill = this.prefillMapper.projectToWindowsAndGlazingPrefill(state.project);
            const cachePrefill = this.prefillMapper.stateToWindowsAndGlazingPrefill(state.cache?.windowsAndGlazing);
            const typeOfGlazingOptions = state.windows.map(window => window.windowType!);
            const options = this.heatloadFormOptionsService.getWindowsAndGlazingFormOptions(
                typeOfGlazingOptions,
                cachePrefill || projectPrefill
            );
            return this.heatloadFormService.getWindowsAndGlazingQuestions(options);
        })
    );

    public isPrefillNeeded(): boolean {
        return this.heatloadService.isRecalculation() && !this.heatloadPrefillService.isPrefilled(FormName.WindowsAndGlazing);
    }

    public getSuccessMessage(formValue: WindowsAndGlazingFormValue): string {
        const windowType = this.translate.instant(windowTypeToPhrase[formValue.typeOfGlazing]);
        const percantageArea = this.translate.instant(windowPercentageAreaToPhraseKeyDictionary[formValue.windowsPercentageArea]);
        return `${percantageArea} • ${windowType}`;
    }

    public isExpanded$ = merge(this.isSameValueSubmitedModernizationAndInsulation$(), this.isPrefillCompletedByBuildingHull$());

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.WindowsAndGlazing);
    }

    private isSameValueSubmitedModernizationAndInsulation$() {
        return this.heatloadStore.sameValueSubmited$.pipe(map(form => form?.formName === FormName.ModernizationsAndInsulation));
    }

    private isPrefillCompletedByBuildingHull$(): Observable<boolean> {
        return this.heatloadPrefillService.state$.pipe(
            map(prefillState => {
                const lastAccordionToBePrefilled = FormName.WindowsAndGlazing;
                const isRecalculation = this.route.firstChild?.firstChild?.snapshot.queryParamMap.get('method');
                return prefillState[lastAccordionToBePrefilled].isPrefilled && !isRecalculation;
            }),
            filter(Boolean),
            first()
        );
    }
}
