import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, Subject, delay, filter, map, merge, tap, throttle } from 'rxjs';
import { WindowService } from './window.service';
import { ActiveAnchor } from '@nx-customer-apps/shared/interfaces';
import { DOCUMENT } from '@angular/common';
import { AnchorIds } from '@nx-customer-apps/shared/enums';

@Injectable({
    providedIn: 'root'
})
export class ActiveAnchorService {
    constructor(private windowService: WindowService, @Inject(DOCUMENT) private document: Document) {}
    private bottomReached$: Subject<void> = new Subject();
    private activeAnchor$: Subject<any> = new Subject();
    public anchorId$: Observable<AnchorIds> = this.activeAnchor$.pipe(
        throttle(anchor =>
            anchor.event === 'click'
                ? merge(
                      this.windowService.scrollEnd$,
                      this.bottomReached$.pipe(
                          delay(50),
                          filter(() => this.isBottomReached())
                      )
                  )
                : EMPTY
        ),
        map((anchor: ActiveAnchor) => anchor.id)
    );

    /* istanbul ignore next */
    public setAnchor(anchor: ActiveAnchor): void {
        this.activeAnchor$.next(anchor);
    }
    /* istanbul ignore next */
    public checkIfBottomReached(): void {
        this.bottomReached$.next();
    }

    public isBottomReached(): boolean {
        return this.windowService.nativeWindow.innerHeight + this.windowService.nativeWindow.scrollY >= this.document.body.offsetHeight;
    }
}
