import { createReducer, on } from '@ngrx/store';
import { percentToFraction } from '@nx-customer-apps/shared/utils';
import { GEGSystemType, HeatPumpOperatingMode, HeatingSystemType } from '@nx-customer-apps/shared/enums';
import { GEGState } from '@nx-customer-apps/shared/interfaces';
import { getSelectedSourceFromGenerators } from '../../shared/utils/selected-energy-source.helper';
import { GEGActions } from './geg.actions';
import { getLowTemperatureForNewSystem } from './new-system-low-temperature.helper';

export const initialState: GEGState = {};

export const GEGReducer = createReducer(
    initialState,
    on(GEGActions.submitSystem, (state, formValue) => {
        const system = { value: formValue.system };
        return { ...initialState, system, cache: state.cache };
    }),
    on(GEGActions.extendSystem, state => {
        return { ...state, system: { value: GEGSystemType.Hybrid } };
    }),
    on(GEGActions.submitCurrentSystemHeatGenerator, (state, formValue) => {
        const energySource = {
            selectedEnergySource: getSelectedSourceFromGenerators(
                state.heatGenerators!,
                formValue.heatGenerator,
                formValue.specificEnergySource
            ),
            selectedHeatGenerator: formValue.heatGenerator
        };

        return {
            ...state,
            currentSystem: {
                ...state.currentSystem,
                heatGeneratorDetails: undefined,
                energySource
            },
            cache: {
                ...state.cache,
                currentSystem: {
                    ...state.cache?.currentSystem,
                    energySource
                }
            }
        };
    }),
    on(GEGActions.submitNewSystemHeatGenerator, (state, formValue) => {
        const energySource = {
            selectedEnergySource: getSelectedSourceFromGenerators(
                state.heatGenerators!,
                formValue.heatGenerator,
                formValue.specificEnergySource
            ),
            selectedHeatGenerator: formValue.heatGenerator
        };

        return {
            ...state,
            newSystem: {
                heatGeneratorDetails: undefined,
                energySource
            },
            cache: {
                ...state.cache,
                newSystem: {
                    ...state.cache?.newSystem,
                    energySource
                }
            }
        };
    }),
    on(GEGActions.getHeatingSystemForNewSystem, (state, action) => {
        const heatGeneratorDetails = {
            installationYear: action.installationYear
        };
        return {
            ...state,
            newSystem: {
                ...state.newSystem,
                heatGeneratorDetails
            },
            cache: {
                ...state.cache,
                newSystem: {
                    ...state.cache?.newSystem,
                    heatGeneratorDetails
                }
            }
        };
    }),
    on(GEGActions.getHeatingSystemForNewSystemSuccess, (state, action) => {
        const heatGeneratorDetails = {
            ...state.newSystem?.heatGeneratorDetails,
            boilerType: action.heatingSystem.type as HeatingSystemType,
            heatPumpOperatingMode: action.heatingSystem.operatingModes?.[0] as HeatPumpOperatingMode,
            lowTemperature: getLowTemperatureForNewSystem(action.heatingSystem)
        };
        return {
            ...state,
            newSystem: {
                ...state.newSystem,
                heatGeneratorDetails
            },
            cache: {
                ...state.cache,
                currentSystem: {
                    ...state.cache?.currentSystem,
                    heatGeneratorDetails
                }
            }
        };
    }),
    on(GEGActions.evaluateGEGSuccess, (state, action) => {
        return {
            ...state,
            evaluation: action.evaluation
        };
    }),
    on(GEGActions.submitCurrentSystemHeatGeneratorDetails, (state, formValue) => {
        const heatGeneratorDetails = {
            boilerType: formValue.boilerType,
            heatPumpOperatingMode: formValue?.heatPumpOperatingMode,
            lowTemperature: formValue.boilerTypeSubquestion?.lowTemperature,
            warmWaterByHeatGenerator: formValue.boilerTypeSubquestion?.warmWaterByHeatGenerator,
            gasLossExhaust: percentToFraction(formValue.gasLossExhaust),
            gasLossExhaustKnown: formValue.gasLossExhaustKnown?.isKnown,
            installationYear: formValue.installationYear,
            nominalPower: formValue.nominalPower
        };

        return {
            ...state,
            currentSystem: {
                ...state.currentSystem,
                heatGeneratorDetails
            },
            cache: {
                ...state.cache,
                currentSystem: {
                    ...state.cache?.currentSystem,
                    heatGeneratorDetails
                }
            }
        };
    }),
    on(GEGActions.getHeatGeneratorsSuccess, (state, action) => {
        return { ...state, heatGenerators: [...action.generators] };
    }),
    on(GEGActions.getHeatingSystemsForCurrentSystemSuccess, (state, action) => {
        return {
            ...state,
            currentSystem: {
                ...state.currentSystem,
                heatingSystems: action.heatingSystems
            }
        };
    }),
    on(GEGActions.resetGEG, () => initialState),
    on(GEGActions.resetEvaluatedGEGResponse, state => {
        return {
            ...state,
            evaluation: undefined
        };
    }),
    on(GEGActions.cacheState, state => {
        return {
            ...state,
            stepCache: state
        };
    }),
    on(GEGActions.restoreStateFromCache, state => {
        return state.stepCache || state;
    }),
    on(GEGActions.submitSameValue, (state, action) => {
        const { formName, value } = action;
        return { ...state, sameValueSubmited: { formName, value } };
    }),
    on(GEGActions.resetSameValue, state => {
        return { ...state, sameValueSubmited: null };
    })
);
