import { WindowType } from '@nx-customer-apps/shared/enums';
import { EnumDictionary } from '../types';

export const windowTypeToOrder: EnumDictionary<WindowType, number> = {
    [WindowType.SingleGlazing]: 1,
    [WindowType.DoubleGlazing]: 2,
    [WindowType.TripleGlazingWood]: 3,
    [WindowType.TripleGlazingPassive]: 4,
    [WindowType.InsulatedGlazingPlastic]: 5,
    [WindowType.InsulatedGlazingAluminum]: 6
};
