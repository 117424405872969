import { ControlTypes } from '@nx-customer-apps/shared/enums';
import { DropdownItem, QuestionOptions } from '@nx-customer-apps/shared/interfaces';
import { QuestionBase } from './question-base.model';

export class Dropdown extends QuestionBase<string> {
    override controlType = ControlTypes.Dropdown;
    public options: DropdownItem[];
    public conditionalHint: string;
    constructor(options: QuestionOptions<string>) {
        super(options);
        this.options = options.options || [];
        this.conditionalHint = options.conditionalHint || '';
    }
}
