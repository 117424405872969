import { Injectable } from '@angular/core';
import { withLatestFrom, map, merge, switchMap, EMPTY, of, Observable } from 'rxjs';
import { HeatloadPrefillService } from './heatload-prefill.service';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { ProjectStore } from '../../state/project/project.store';
import { HeatloadFormService } from './heatload-form.service';
import { FormName, ProjectType, RoofType, SuccessMessages } from '@nx-customer-apps/shared/enums';
import { HeatloadService } from './heatload.service';
import { BuildingHull, HeatloadState } from '@nx-customer-apps/shared/interfaces';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { ProjectResponseGetById } from '@nx-customer-apps/api-planning-projects';
import { HeatloadPrefillMapper, ProjectsService } from '../../services';
import { LodashService } from '@nx-customer-apps/shared/services';
import { AccordionService } from '../../shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class ModernizationsAndInsulationService implements AccordionService {
    constructor(
        private heatloadPrefillService: HeatloadPrefillService,
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private heatloadStore: HeatloadStore,
        private heatloadService: HeatloadService,
        private projectStore: ProjectStore,
        private projectsService: ProjectsService,
        private heatloadFormService: HeatloadFormService
    ) {}

    private questionsOnBuildingHull$ = this.heatloadStore.buildingHull$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$),
        map(([buildingHull, project, cache]) => ({
            buildingHull,
            isBasementPresent: !!buildingHull?.ventilationAndHeating.basementPresent,
            project,
            cache
        })),

        map(state => {
            if (!state.buildingHull) {
                return null;
            }

            if (this.isAtticHeated(state.buildingHull)) {
                return null;
            }

            return this.returnQuestions(state);
        })
    );

    private questionsOnRoofType$ = this.heatloadStore.roofType$.pipe(
        withLatestFrom(this.heatloadStore.buildingHull$, this.projectStore.project$, this.heatloadStore.cache$),
        map(([roofType, buildingHull, project, cache]) => ({
            roofType,
            isBasementPresent: !!buildingHull?.ventilationAndHeating.basementPresent,
            project,
            cache
        })),
        switchMap(state => {
            if (!state.roofType) {
                return EMPTY;
            }
            if (!this.isFlatRoofType(state.roofType.value)) {
                return of(null);
            }

            return of(this.returnQuestions(state));
        })
    );

    private questionsOnRoofDimensions$ = this.heatloadStore.roofDimensions$.pipe(
        withLatestFrom(this.heatloadStore.buildingHull$, this.projectStore.project$, this.heatloadStore.cache$),
        map(([roofDimensions, buildingHull, project, cache]) => ({
            roofDimensions,
            isBasementPresent: !!buildingHull?.ventilationAndHeating.basementPresent,
            project,
            cache
        })),
        switchMap(state => {
            if (!state.roofDimensions) {
                return EMPTY;
            }

            return of(this.returnQuestions(state));
        })
    );

    public questions$ = merge(this.questionsOnBuildingHull$, this.questionsOnRoofType$, this.questionsOnRoofDimensions$);

    private returnQuestions(state: { project?: ProjectResponseGetById; isBasementPresent: boolean; cache: HeatloadState | undefined }) {
        const projectPrefill = this.prefillMapper.projectToModernizationsAndInsulationPrefill(state.project!);
        const cachePrefill = this.prefillMapper.stateToModernizationsAndInsulationPrefill(state.cache?.modernizationsAndInsulation);
        const formOptions = this.heatloadFormOptionsService.getModernizationsAndInsulationFormOptions(
            state.project!,
            state.isBasementPresent,
            cachePrefill || projectPrefill
        );
        return this.heatloadFormService.getModernizationsAndInsulationQuestions(formOptions);
    }

    public title$ = this.projectStore.project$.pipe(
        map(project => this.heatloadService.getInsulationTitle(project?.projectType as ProjectType))
    );

    public description$ = this.heatloadStore.buildingHull$.pipe(
        withLatestFrom(this.projectStore.project$, (buildingHull, project) => ({ buildingHull, project })),
        map(state => {
            return !this.isAtticHeated(state.buildingHull)
                ? this.heatloadService.getModernizationsAndInsulationDescription(state.project!.projectType as ProjectType)
                : '';
        })
    );

    public isPrefillNeeded(): boolean {
        return this.heatloadService.isRecalculation() && !this.heatloadPrefillService.isPrefilled(FormName.ModernizationsAndInsulation);
    }

    public hasPrefillSource$: Observable<boolean> = this.projectStore.project$.pipe(
        map(project => {
            const hasInsulations =
                !LodashService.isNil(project?.building.atticInsulation) &&
                !LodashService.isNil(project?.building.roofInsulation) &&
                !LodashService.isNil(project?.building.wallsInsulation);

            const hasConstructionYears =
                !LodashService.isNil(project?.building.atticConstructionYear) &&
                !LodashService.isNil(project?.building.buildingConstructionYear) &&
                !LodashService.isNil(project?.building.roofConstructionYear) &&
                !LodashService.isNil(project?.building.wallsConstructionYear) &&
                !LodashService.isNil(project?.building.windowsConstructionYear);

            const hasCellarInsulation = !LodashService.isNil(project?.building.cellarInsulation);
            const hasCellarModernizationYear = !LodashService.isNil(project?.building.cellarConstructionYear);

            if (this.projectsService.isNewConstruction(project?.projectType as ProjectType)) {
                return project?.building.heatedCellar ? hasInsulations && hasCellarInsulation : hasInsulations;
            }

            return project?.building.heatedCellar
                ? hasInsulations && hasConstructionYears && hasCellarInsulation && hasCellarModernizationYear
                : hasInsulations && hasConstructionYears;
        })
    );

    public getSuccessMessage(): string {
        return SuccessMessages.StepCompleted;
    }

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.ModernizationsAndInsulation);
        this.heatloadPrefillService.setAsPrefilled(FormName.RoofType);
        this.heatloadPrefillService.setAsPrefilled(FormName.RoofDimensions);
    }

    public isExpanded$ = this.heatloadStore.sameValueSubmited$.pipe(
        map(form => {
            const isAfterBuildingHull = form?.formName === FormName.BuildingHull && !this.isAtticHeated(form.value);
            const isAfterRoofType = form?.formName === FormName.RoofType && this.isFlatRoofType(form.value.roofType);
            const isAfterRoofDimensions = form?.formName === FormName.RoofDimensions;
            return isAfterBuildingHull || isAfterRoofType || isAfterRoofDimensions;
        })
    );

    private isFlatRoofType(roofType: RoofType): boolean {
        return roofType === RoofType.Flat;
    }

    private isAtticHeated(buildingHull: BuildingHull | undefined) {
        return buildingHull?.ventilationAndHeating.atticHeated;
    }
}
