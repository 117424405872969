import { createAction, props } from '@ngrx/store';
import { AppSpecificDataState, ReportConfiguration } from '@nx-customer-apps/shared/interfaces';

export namespace AppSpecificDataActions {
    export const setAppSpecificData = createAction('[APP SPECIFIC DATA] Set App Specific Data', props<AppSpecificDataState>());
    export const setReportConfiguration = createAction(
        '[APP SPECIFIC DATA] Set Report Configuration',
        props<{ reportConfiguration: ReportConfiguration[] }>()
    );
}
