import {
    ChangeDetectionStrategy,
    Component,
    Input,
    EventEmitter,
    Output,
    OnChanges,
    SimpleChanges,
    TrackByFunction,
    TemplateRef
} from '@angular/core';
import { ProjectBasicGetRecordsList } from '@nx-customer-apps/api-planning-projects';
import { OrderType } from '@nx-customer-apps/shared/enums';
import { Column, ConfirmConfig, ListOrder } from '@nx-customer-apps/shared/interfaces';

@Component({
    selector: 'vp-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent implements OnChanges {
    public readonly orderType = OrderType;
    public displayedColumns: string[] | undefined = [];

    @Output() public rowClick: EventEmitter<any> = new EventEmitter();
    @Output() public orderChange: EventEmitter<ListOrder> = new EventEmitter();

    @Input() public columns: Column[];
    @Input() public data: any[];
    @Input() public pointer: boolean;
    @Input() public deleteConfirmConfig: ConfirmConfig;
    @Input() public order: ListOrder | null;
    @Input() public trackByFn: TrackByFunction<ProjectBasicGetRecordsList> = (index, project) => project.id;
    @Input() public contextMenuTemplateRef?: TemplateRef<any> | undefined;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['columns']?.currentValue) {
            this.displayedColumns = this.columns.map(column => column.name);
        }
    }

    public onOrderChange(orderBy: string, orderType?: OrderType): void {
        switch (orderType) {
            case OrderType.Desc:
                this.orderChange.emit({});
                break;

            case OrderType.Asc:
                this.orderChange.emit({ orderBy, orderType: OrderType.Desc });
                break;

            default:
                this.orderChange.emit({ orderBy, orderType: OrderType.Asc });
                break;
        }
    }
}
