import { createAction, props } from '@ngrx/store';
import { HeatGeneratorInformation, SystemItem } from '@nx-customer-apps/api-planning-projects';
import { ConsumptionProfile, EnergySource, HeatGeneratorType } from '@nx-customer-apps/shared/enums';
import {
    BuildingFormValue,
    Device,
    DistributionAndScheduleFormValue,
    EnergyConsumptionFormValue,
    HeatGeneratorDetailsFormValue,
    HeatGeneratorFormValue,
    LivingAreaFormValue,
    SameFormValue,
    SubsidyEligibility
} from '@nx-customer-apps/shared/interfaces';

export namespace HeatPumpIntermediaryActions {
    export const submitDevice = createAction('[Heat Pump Intermediary] Submit Device', props<Device>());

    export const submitSubsidyEligibility = createAction(
        '[Heat Pump Intermediary] Submit Subsidy Eligibility',
        props<SubsidyEligibility>()
    );

    export const submitBuilding = createAction('[Heat Pump Intermediary] Submit Building', props<BuildingFormValue>());

    export const submitLivingArea = createAction('[Heat Pump Intermediary] Submit Living Area', props<LivingAreaFormValue>());

    export const getConsumptionProfiles = createAction('[Heat Pump Intermediary] Get Consumption Profiles');

    export const getConsumptionProfilesSuccess = createAction(
        '[Heat Pump Intermediary] Get Consumption Profiles Success',
        props<{ consumptionProfiles: ConsumptionProfile[] }>()
    );

    export const getHeatGenerators = createAction(
        '[Heat Pump Intermediary] Get Heat Generators',
        // selectedEnergySourceType and selectedHeatGeneratorType are used to compute the state with selectedEnergySource and selectedHeatedGenerator when the state is prefilled.
        ({
            selectedEnergySourceType,
            selectedHeatGeneratorType
        }: { selectedEnergySourceType?: EnergySource; selectedHeatGeneratorType?: HeatGeneratorType } | undefined = {}) => ({
            selectedEnergySourceType,
            selectedHeatGeneratorType
        })
    );

    export const getHeatGeneratorsSuccess = createAction(
        '[Heat Pump Intermediary] Get Heat Generators Success',
        // selectedEnergySourceType and selectedHeatGeneratorType are used to compute the state with selectedEnergySource and selectedHeatedGenerator when the state is prefilled.
        props<{
            heatGenerators: HeatGeneratorInformation[];
            selectedEnergySourceType?: EnergySource;
            selectedHeatGeneratorType?: HeatGeneratorType;
        }>()
    );

    export const getHeatingSystems = createAction('[Heat Pump Intermediary] Get Heating Systems', props<{ installationYear: string }>());

    export const getHeatingSystemsSuccess = createAction(
        '[Heat Pump Intermediary] Get Heating Systems Success',
        props<{ heatingSystems: SystemItem[] }>()
    );

    export const submitHeatGenerator = createAction('[Heat Pump Intermediary] Submit HEAT Generator', props<HeatGeneratorFormValue>());

    export const submitHeatGeneratorDetails = createAction(
        '[Heat Pump Intermediary] Submit Heat Generator Details',
        props<HeatGeneratorDetailsFormValue>()
    );

    export const submitEnergyConsumption = createAction(
        '[Heat Pump Intermediary] Submit Energy Consumption',
        props<EnergyConsumptionFormValue>()
    );

    export const submitHeatingDistribution = createAction(
        '[Heat Pump Intermediary] Submit Heating Distribution',
        props<DistributionAndScheduleFormValue>()
    );

    export const submitSameValue = createAction('[Heat Pump Intermediary] Submit Same Value', props<SameFormValue>());

    export const resetSameValue = createAction('[Heat Pump Intermediary] Reset Same Value');

    export const resetHeatPumpIntermediary = createAction('[Heat Pump Intermediary] Reset');
}
