import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '@nx-customer-apps/shared/interfaces';
import { ProjectAccessSelectors } from './project-access.selectors';
import { ProjectAccessActions } from './project-access.actions';
import { WindowService } from '@nx-customer-apps/shared/services';

@Injectable({ providedIn: 'root' })
export class ProjectAccessStore {
    public state$ = this.store$.pipe(select(ProjectAccessSelectors.getState()));
    public status$ = this.store$.pipe(select(ProjectAccessSelectors.getStatus()));
    public sessionExpiredMessage$ = this.store$.pipe(select(ProjectAccessSelectors.getSessionExpiredMessage()));
    public unlockOnUnload$ = this.store$.pipe(select(ProjectAccessSelectors.getUnlockOnUnload()));

    constructor(private store$: Store<AppState>, private windowsService: WindowService) {
        this.beforeUnloadHandler();
    }

    public lockProject(id: string): void {
        this.store$.dispatch(ProjectAccessActions.lockProject({ id }));
    }

    public unlockProject(id: string): void {
        this.store$.dispatch(ProjectAccessActions.unlockProject({ id }));
    }

    public verifyProjectLock(id: string): void {
        this.store$.dispatch(ProjectAccessActions.verifyProjectLock({ id }));
    }

    public hideExpiredLockSessionMessage(): void {
        this.store$.dispatch(ProjectAccessActions.hideExpiredLockSessionMessage());
    }

    public skipProjectUnlockBeforeUnload(): void {
        this.store$.dispatch(ProjectAccessActions.skipProjectUnlockBeforeUnload());
    }

    public unlockProjectBeforeUnload(): void {
        this.store$.dispatch(ProjectAccessActions.unlockProjectBeforeUnload());
    }

    private beforeUnloadHandler(): void {
        /**
         * This event is fired when the tab or window is about to unload its resources.
         */
        this.windowsService.nativeWindow.addEventListener('beforeunload', (_event: BeforeUnloadEvent) => {
            this.unlockProjectBeforeUnload();
        });
    }
}
