<vp-dialog-header title="{{ 'ONBOARDING.TITLE' | translate }}" [context]="{}" (close)="onClose()"></vp-dialog-header>

<mat-dialog-content class="vp-pre-line py-0 vp-scrollbar-vertical">
    <div class="carousel-container" data-testid="onboarding-slides">
        <owl-carousel-o #carouselRef [options]="carouselOptions" class="ca-carousel" (changed)="setSelectedSlide($event)">
            <ng-template carouselSlide *ngFor="let slide of allSlides; let isLast = last; let index = index">
                <div class="px-2 vp-border-box vp-h-100" data-testid="onboarding-slides-item">
                    <img height="340" [src]="slide.imageUrl" />
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <h3>{{ allSlides[selectedSlideIndex].title | translate }}</h3>
    <p>{{ allSlides[selectedSlideIndex].description | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end align-items-center vp-gap-8 py-3 px-4">
    <ng-container *ngTemplateOutlet="allSlides?.length! > 1 ? multiSlideButtonSet : singleSlideButtonSet"></ng-container>
</mat-dialog-actions>

<ng-template #multiSlideButtonSet>
    <button
        *ngIf="!isFirstSlide()"
        mat-button
        backlink
        disableRipple="true"
        class="ca-button ca-button-s ca-button-no-padding me-auto"
        data-testid="onboarding-back-btn"
        (click)="carouselRef?.prev()"
    >
        <ca-icon icon="arrow-left" class="ca-icon-s"></ca-icon>
        <span>{{ 'COMMON.BUTTONS.BACK' | translate }}</span>
    </button>

    <a mat-stroked-button class="ca-button ca-button-s" [href]="FAQUrl$ | async" target="_blank" data-testid="onboarding-more-info-btn">
        <span> {{ 'COMMON.BUTTONS.MORE_INFORMATION' | translate }} </span>
        <ca-icon icon="arrow-bounded" class="ca-icon-s"></ca-icon>
    </a>
    <button
        *ngIf="!isLastSlide()"
        mat-flat-button
        class="ca-button ca-button-s"
        (click)="carouselRef.next()"
        data-testid="onboarding-next-btn"
    >
        {{ 'COMMON.BUTTONS.NEXT' | translate }}
    </button>
    <button *ngIf="isLastSlide()" mat-flat-button class="ca-button ca-button-s" (click)="onClose()" data-testid="onboarding-close-btn">
        {{ 'COMMON.BUTTONS.CLOSE' | translate }}
    </button>
</ng-template>

<ng-template #singleSlideButtonSet>
    <button mat-stroked-button class="ca-button ca-button-s" (click)="onClose()" data-testid="onboarding-skip-btn">
        {{ 'COMMON.BUTTONS.SKIP' | translate }}
    </button>
    <a mat-flat-button class="ca-button ca-button-s" [href]="FAQUrl$ | async" target="_blank" data-testid="onboarding-more-info-btn">
        <span> {{ 'COMMON.BUTTONS.MORE_INFORMATION' | translate }} </span>
        <ca-icon icon="arrow-bounded" class="ca-icon-s"></ca-icon>
    </a>
</ng-template>
