import { Injectable } from '@angular/core';
import { ProjectResponseGetById } from '@nx-customer-apps/api-planning-projects';
import { FormKeys } from '@nx-customer-apps/shared/enums';
import { BuildingFormValue, DeviceFormValue, SubsidyEligibility, SubsidyEligibilityValues } from '@nx-customer-apps/shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class HeatPumpIntermediaryPrefillMapper {
    public projectToDevicePrefill(project: ProjectResponseGetById | undefined): DeviceFormValue | undefined {
        if (!project) {
            return undefined;
        }

        return {
            [FormKeys.IsOldDeviceUsed]: project?.building.currentHeatingSystem?.currentHeaterAsPeakLoadDevice!
        };
    }
    public stateToDevicePrefill(state: { value: boolean } | undefined): DeviceFormValue | undefined {
        if (!state) {
            return undefined;
        }

        return {
            [FormKeys.IsOldDeviceUsed]: state.value
        };
    }

    public projectToBuildingPrefill(project: ProjectResponseGetById | undefined): BuildingFormValue | undefined {
        if (!project) {
            return undefined;
        }
        return {
            [FormKeys.BuildingConstructionYear]: project?.building.buildingConstructionYear?.toString()
        };
    }

    public stateToBuildingPrefill(state: BuildingFormValue | undefined): BuildingFormValue | undefined {
        if (!state) {
            return undefined;
        }
        return {
            [FormKeys.BuildingConstructionYear]: state[FormKeys.BuildingConstructionYear]
        };
    }

    public projectToSubsidyPrefill(project: ProjectResponseGetById | undefined): SubsidyEligibility | undefined {
        if (!project) {
            return undefined;
        }
        return {
            [FormKeys.IsIncomeLowForSubsidy]: project.building.household?.isIncomeLowForSubsidy!,
            [FormKeys.IsOwnerOfTheBuilding]: project.building.household?.isOwnerOfTheBuilding!,
            [FormKeys.IsOwnerMainResidence]: project.building.household?.isOwnerMainResidence!,
            [FormKeys.IsOldHeatingSystemDefective]: project.building.household?.isOldHeatingSystemDefective,
            [FormKeys.NumberOfResidentialUnits]: project.building?.numberOfResidentialUnits
        };
    }
    public stateToSubsidyPrefill(state: SubsidyEligibilityValues | undefined): SubsidyEligibility | undefined {
        if (!state) {
            return undefined;
        }
        return {
            [FormKeys.IsIncomeLowForSubsidy]: state.isIncomeLowForSubsidy?.value!,
            [FormKeys.IsOwnerOfTheBuilding]: state.isOwnerOfTheBuilding?.value!,
            [FormKeys.IsOwnerMainResidence]: state.isOwnerMainResidence?.value!,
            [FormKeys.IsOldHeatingSystemDefective]: state.isOldHeatingSystemDefective?.value,
            [FormKeys.NumberOfResidentialUnits]: state.numberOfResidentialUnits?.value
        };
    }
}
