import { ReportConfigurationSection } from '@nx-customer-apps/shared/enums';
import { ReportConfiguration } from '@nx-customer-apps/shared/interfaces';

export const defaultReportConfiguration: ReportConfiguration[] = [
    {
        section: ReportConfigurationSection.GeneralInformation,
        subSections: [
            {
                section: ReportConfigurationSection.GeneralProjectData
            },
            {
                section: ReportConfigurationSection.CurrentSystem
            },
            {
                section: ReportConfigurationSection.PlannedSystem
            },
            {
                section: ReportConfigurationSection.EnergyPrices
            },
            {
                section: ReportConfigurationSection.HeatLoadCalculations
            },
            {
                section: ReportConfigurationSection.GEGRecommendations
            }
        ]
    },
    {
        section: ReportConfigurationSection.HeatPumpPlan,
        subSections: [
            {
                section: ReportConfigurationSection.HPSimulationResults
            },
            {
                section: ReportConfigurationSection.HPFinancialPerformance
            },
            {
                section: ReportConfigurationSection.AppendixJAZ
            },
            {
                section: ReportConfigurationSection.HPBillOfMaterials
            }
        ]
    },
    {
        section: ReportConfigurationSection.PVPlan,
        subSections: [
            {
                section: ReportConfigurationSection.Roofs
            },
            {
                section: ReportConfigurationSection.PVTechnicalDetails
            },
            {
                section: ReportConfigurationSection.PVSimulationResults
            },
            {
                section: ReportConfigurationSection.PVInstallationPlans
            },
            {
                section: ReportConfigurationSection.PVStaticsAnalysisReport
            },
            {
                section: ReportConfigurationSection.PVFinancialPerformance
            },
            {
                section: ReportConfigurationSection.PVBillOfMaterials
            }
        ]
    },
    {
        section: ReportConfigurationSection.VentilationPlan
    },
    {
        section: ReportConfigurationSection.FullSystem,
        subSections: [
            {
                section: ReportConfigurationSection.FullSystemSimulation
            },
            {
                section: ReportConfigurationSection.FinancialPerformance
            },
            {
                section: ReportConfigurationSection.BillOfMaterials
            }
        ]
    },
    {
        section: ReportConfigurationSection.LegalNotice
    }
];
