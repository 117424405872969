import { Injectable } from '@angular/core';
import { WindowService } from './window.service';
import { GtmEvent } from '@nx-customer-apps/shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class GoogleTagManagerService {
    constructor(private window: WindowService) {}

    public pushEvent(event: GtmEvent): void {
        if (this.window.nativeWindow.dataLayer) {
            this.window.nativeWindow.dataLayer.push(event);
        }
    }
}
