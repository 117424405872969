import { Action, createReducer, on } from '@ngrx/store';
import { AppSpecificDataState } from '@nx-customer-apps/shared/interfaces';
import { AppSpecificDataActions } from './app-specific-data.actions';

import data from '../../../../../../package.json';
import { defaultReportConfiguration } from './default-report-configuration';

export const initialAppSpecificDataState: AppSpecificDataState = {
    viguidePlanningSpecificData: {
        data: {
            reportConfiguration: defaultReportConfiguration
        }
    }
};

const reducer = createReducer(
    initialAppSpecificDataState,
    on(AppSpecificDataActions.setAppSpecificData, (state, { viguidePlanningSpecificData }) => {
        return {
            viguidePlanningSpecificData: {
                ...viguidePlanningSpecificData,
                data: {
                    ...viguidePlanningSpecificData.data,
                    reportConfiguration: (<any>viguidePlanningSpecificData?.data)?.reportConfiguration || defaultReportConfiguration
                },
                applicationVersion: viguidePlanningSpecificData.applicationVersion || data.version
            }
        };
    }),
    on(AppSpecificDataActions.setReportConfiguration, (state, { reportConfiguration }) => {
        return {
            ...state,
            viguidePlanningSpecificData: {
                ...state.viguidePlanningSpecificData,
                data: {
                    reportConfiguration
                }
            }
        };
    })
);

export function appSpecificDataReducer(state: AppSpecificDataState | undefined, action: Action) {
    return reducer(state, action);
}
