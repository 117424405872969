import { createReducer, on } from '@ngrx/store';
import { HeatloadState, InsulationFormValue, ModernizationsAndInsulationFormValue } from '@nx-customer-apps/shared/interfaces';
import { getSelectedSourceFromGenerators } from '../../shared/utils/selected-energy-source.helper';
import { HeatloadActions } from './heatload.actions';
import { percentToFraction } from '@nx-customer-apps/shared/utils';

export const initialState: HeatloadState = {};

export const heatloadReducer = createReducer(
    initialState,
    on(HeatloadActions.submitCalculationMethod, (state, formValue) => {
        return formValue.methods.standardMethod
            ? {
                  ...initialState,
                  definedValue: undefined,
                  calculationMethod: { value: formValue.methods.standardMethod },
                  cache: { ...state.cache, calculationMethod: { value: formValue.methods.standardMethod } }
              }
            : {
                  ...initialState,
                  calculationMethod: undefined,
                  definedValue: formValue.methods.definedValue,
                  cache: { ...state.cache, definedValue: formValue.methods.definedValue }
              };
    }),
    on(HeatloadActions.submitLivingArea, (state, formValue) => {
        const livingArea = {
            value: formValue.livingArea
        };
        return {
            ...state,
            energyStandards: undefined,
            modernization: undefined,
            ventilation: undefined,
            livingArea,
            cache: {
                ...state.cache,
                livingArea
            }
        };
    }),
    on(HeatloadActions.submitEnergyCertificate, (state, formValue) => {
        const energyCertificate = {
            surroundingArea: formValue.surroundingArea,
            buildingVolume: formValue.buildingVolume,
            airExchangeRate: formValue.airExchangeRate,
            specificTransmissionHeatLoss: formValue.specificTransmissionHeatLoss
        };
        return {
            ...state,
            energyCertificate,
            cache: {
                ...state.cache,
                energyCertificate
            }
        };
    }),
    on(HeatloadActions.submitBuildingHull, (state, formValue) => {
        const buildingHull = {
            buildingDimensions: formValue.buildingDimensions,
            buildingPosition: formValue.buildingPosition,
            ventilationAndHeating: formValue.ventilationAndHeating
        };

        return {
            ...state,
            windows: undefined,
            modernizationsAndInsulation: undefined,
            roofType: undefined,
            roofDimensions: undefined,
            windowsAndGlazing: undefined,
            buildingHull,
            cache: {
                ...state.cache,
                buildingHull
            }
        };
    }),
    on(HeatloadActions.submitHeatGenerator, (state, formValue) => {
        const heatingEnergySource = {
            selectedEnergySource: getSelectedSourceFromGenerators(
                state.heatGenerators!,
                formValue.heatGenerator,
                formValue.specificEnergySource
            ),
            selectedHeatGenerator: formValue.heatGenerator
        };

        return {
            ...state,
            energyConsumption: undefined,
            heatingDistribution: undefined,
            heatGeneratorDetails: undefined,
            heatingEnergySource,
            cache: {
                ...state.cache,
                heatingEnergySource
            }
        };
    }),
    on(HeatloadActions.submitModernization, (state, formValue) => {
        const modernization = {
            buildingConstructionYear: formValue.buildingConstructionYear,
            roofModernization: formValue.roofModernization,
            wallsModernization: formValue.wallsModernization,
            windowsModernization: formValue.windowsModernization
        };

        return {
            ...state,
            ventilation: undefined,
            modernization,
            cache: {
                ...state.cache,
                modernization
            }
        };
    }),
    on(HeatloadActions.submitRoofType, (state, formValue) => {
        const roofType = { value: formValue.roofType };

        return {
            ...state,
            roofDimensions: undefined,
            windows: undefined,
            modernizationsAndInsulation: undefined,
            windowsAndGlazing: undefined,
            roofType,
            cache: {
                ...state.cache,
                roofType
            }
        };
    }),
    on(HeatloadActions.submitWindowsAndGlazing, (state, formValue) => {
        const windowsAndGlazing = {
            typeOfGlazing: formValue.typeOfGlazing,
            windowsPercentageArea: formValue.windowsPercentageArea
        };

        return {
            ...state,
            windowsAndGlazing,
            cache: {
                ...state.cache,
                windowsAndGlazing
            }
        };
    }),
    on(HeatloadActions.submitHeatGeneratorDetails, (state, formValue) => {
        const heatGeneratorDetails = {
            boilerType: formValue.boilerType,
            heatPumpOperatingMode: formValue?.heatPumpOperatingMode,
            lowTemperature: formValue.boilerTypeSubquestion?.lowTemperature,
            warmWaterByHeatGenerator: formValue.boilerTypeSubquestion?.warmWaterByHeatGenerator,
            gasLossExhaust: percentToFraction(formValue.gasLossExhaust),
            gasLossExhaustKnown: formValue.gasLossExhaustKnown?.isKnown,
            installationYear: formValue.installationYear,
            nominalPower: formValue.nominalPower
        };
        const selectedHeatingSystem = state.heatingSystems!.find(system => system.type === formValue.boilerType);

        return {
            ...state,
            energyConsumption: undefined,
            heatingDistribution: undefined,
            heatGeneratorDetails,
            selectedHeatingSystem,
            cache: {
                ...state.cache,
                heatGeneratorDetails,
                selectedHeatingSystem
            }
        };
    }),
    on(HeatloadActions.submitModernizationsAndInsulation, (state, formValue) => {
        if ((<ModernizationsAndInsulationFormValue>formValue).buildingConstructionYear) {
            const modernizationsAndInsulation = {
                buildingConstructionYear: (<ModernizationsAndInsulationFormValue>formValue).buildingConstructionYear,
                windowsConstructionYear: (<ModernizationsAndInsulationFormValue>formValue).windowsConstructionYear,
                roofRenovationAndInsulation: (<ModernizationsAndInsulationFormValue>formValue).roofRenovationAndInsulation,
                upperFloorRenovationAndInsulation: (<ModernizationsAndInsulationFormValue>formValue).upperFloorRenovationAndInsulation,
                wallsRenovationAndInsulation: (<ModernizationsAndInsulationFormValue>formValue).wallsRenovationAndInsulation,
                basementRenovationAndInsulation: (<ModernizationsAndInsulationFormValue>formValue).basementRenovationAndInsulation
            };

            return {
                ...state,
                windowsAndGlazing: undefined,
                modernizationsAndInsulation,
                cache: {
                    ...state.cache,
                    modernizationsAndInsulation
                }
            };
        }

        const modernizationsAndInsulation = {
            roofInsulation: (<InsulationFormValue>formValue).roofInsulation,
            atticInsulation: (<InsulationFormValue>formValue).atticInsulation,
            wallsInsulation: (<InsulationFormValue>formValue).wallsInsulation,
            cellarInsulation: (<InsulationFormValue>formValue).cellarInsulation
        };
        return {
            ...state,
            windowsAndGlazing: undefined,
            modernizationsAndInsulation,
            cache: {
                ...state.cache,
                modernizationsAndInsulation
            }
        };
    }),
    on(HeatloadActions.submitEnergyStandard, (state, formValue) => {
        const selectedEnergyStandard = { ...formValue.energyStandards };
        return {
            ...state,
            ventilation: undefined,
            selectedEnergyStandard,
            cache: {
                ...state.cache,
                selectedEnergyStandard
            }
        };
    }),
    on(HeatloadActions.submitVentilation, (state, formValue) => {
        const ventilation = { value: formValue.ventilation };
        return {
            ...state,
            ventilation,
            cache: {
                ...state.cache,
                ventilation
            }
        };
    }),
    on(HeatloadActions.submitRoofDimensions, (state, formValue) => {
        const roofDimensions = formValue.roofDimensions;
        return {
            ...state,
            windowsAndGlazing: undefined,
            modernizationsAndInsulation: undefined,
            windows: undefined,
            roofDimensions,
            cache: {
                ...state.cache,
                roofDimensions
            }
        };
    }),

    on(HeatloadActions.submitEnergyConsumption, (state, formValue) => {
        const energyConsumption = {
            requiredEnergyPerYear: formValue.requiredEnergyPerYear,
            nonHeatingElectricityPerYear: formValue.nonHeatingElectricityPerYear
        };
        return {
            ...state,
            heatingDistribution: undefined,
            energyConsumption,
            cache: {
                ...state.cache,
                energyConsumption
            }
        };
    }),
    on(HeatloadActions.submitHeatingDistribution, (state, formValue) => {
        const heatingDistribution = {
            distributionMethod: formValue.distributionMethod,
            heatingFlowTemperature: formValue.heatingFlowTemperature,
            heatingSchedule: formValue.heatingSchedule
        };
        return {
            ...state,
            heatingDistribution,
            cache: {
                ...state.cache,
                heatingDistribution
            }
        };
    }),
    on(HeatloadActions.getHeatGeneratorsSuccess, (state, action) => {
        return { ...state, heatGenerators: [...action.generators] };
    }),
    on(HeatloadActions.getEnergyStandardsSuccess, (state, action) => {
        return { ...state, energyStandards: [...action.standards] };
    }),
    on(HeatloadActions.getWindowsSuccess, (state, action) => {
        return { ...state, windows: [...action.windows] };
    }),
    on(HeatloadActions.getHeatingSystemsSuccess, (state, action) => {
        return { ...state, heatingSystems: action.heatingSystems };
    }),
    on(HeatloadActions.submitSameValue, (state, action) => {
        const { formName, value } = action;
        return { ...state, sameValueSubmited: { formName, value } };
    }),
    on(HeatloadActions.resetSameValue, state => {
        return { ...state, sameValueSubmited: null };
    }),
    on(HeatloadActions.resetHeatload, () => initialState)
);
