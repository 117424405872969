import { Pipe, PipeTransform } from '@angular/core';
import { QuestionBase } from '@nx-customer-apps/shared/utils';

@Pipe({
    name: 'formFieldStyles'
})
export class FormFieldStylesPipe implements PipeTransform {
    transform(question: QuestionBase<any>): string {
        const classes: string[] = [question?.styles];
        if (question.label) {
            classes.push('with-label');
        }
        if (question.validators?.length) {
            classes.push('with-error-message');
        }
        return classes.join(' ');
    }
}
