import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '@nx-customer-apps/shared/enums';
import { orderStatusDictionary } from '@nx-customer-apps/shared/utils';

@Pipe({
    name: 'orderStatusDictionary'
})
export class OrderStatusDictionaryPipe implements PipeTransform {
    transform(status: OrderStatus): string {
        return orderStatusDictionary[status];
    }
}
