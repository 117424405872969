import { CountryLanguageCodes, LanguageCodes } from '@nx-customer-apps/shared/enums';
import { languageToLocale } from './language-to-locale.dictionary';

export class LocaleUtils {
    static get supportedLocales(): string[] {
        return Object.keys(CountryLanguageCodes).map(key => (<any>CountryLanguageCodes)[key]);
    }

    static get defaultLocale(): string {
        return CountryLanguageCodes.enDE;
    }

    static get defaultLanguageCode(): string {
        const [languageCode, _] = CountryLanguageCodes.enDE.split('-');
        return languageCode;
    }

    static get defaultCountryCode(): string {
        const [_, countryCode] = CountryLanguageCodes.enDE.split('-');
        return countryCode;
    }

    static get defaultBrowserLocale(): string {
        const storageLocale = localStorage.getItem('locale');
        if (storageLocale && LocaleUtils.supportedLocales.some(locale => locale === storageLocale)) {
            return storageLocale;
        }

        const browserLocale = window.navigator.language;
        if (LocaleUtils.supportedLocales.some(locale => locale === browserLocale)) {
            return browserLocale;
        }

        const [browserLanguage, _browserCountry] = browserLocale.split('-');

        return languageToLocale[browserLanguage as LanguageCodes] || LocaleUtils.defaultLocale;
    }

    static parseLocale(locale: string | undefined): string {
        try {
            if (locale) {
                const [language, country] = locale.split('-');
                return `${language.toLowerCase()}-${country.toUpperCase()}`;
            }
            return LocaleUtils.defaultLocale;
        } catch (error) {
            return LocaleUtils.defaultLocale;
        }
    }

    static getCountryCode(locale: string): string {
        return locale?.split('-')[1];
    }

    static getLanguageCode(locale: string): string {
        return locale?.split('-')[0];
    }
}
