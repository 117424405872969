import { createAction, props } from '@ngrx/store';
import {
    ProjectsResponseGetRecordsList,
    BuildingLocationResponse,
    DuplicateProjectResponse
} from '@nx-customer-apps/api-planning-projects';
import {
    ProjectFormValue,
    ProjectsFilter,
    ProjectsOrder,
    ProjectsPagination,
    ProjectsQueryParams,
    RequestProjectsQueryParams
} from '@nx-customer-apps/shared/interfaces';

export namespace ProjectsActions {
    export const getProjects = createAction('[PROJECTS] Get Projects', props<{ queryParams: RequestProjectsQueryParams }>());
    export const getProjectsSuccess = createAction('[PROJECTS] Get Projects Success', props<ProjectsResponseGetRecordsList | any>());
    export const getProjectsError = createAction('[PROJECTS] Get Projects Error');
    export const deleteProject = createAction('[PROJECTS] Delete Project', props<{ projectId: string }>());
    export const deleteProjectSuccess = createAction('[PROJECTS] Delete Project Success');
    export const duplicateProject = createAction(
        '[PROJECTS] Duplicate Project',
        props<{ projectId: string; projectName: string; numberOfPersons?: number }>()
    );
    export const duplicateProjectSuccess = createAction(
        '[PROJECTS] Duplicate Project Success',
        props<{ response: DuplicateProjectResponse; projectName: string }>()
    );
    export const validateRouteQueryParams = createAction(
        '[PROJECTS] Validate Route Query Params',
        props<{ queryParams: ProjectsQueryParams }>()
    );
    export const handleRouteQueryParams = createAction(
        '[PROJECTS] Handle Route Query Params',
        props<{ queryParams: ProjectsQueryParams }>()
    );
    export const clearProjects = createAction('[PROJECTS] Clear Projects');
    export const filterProjects = createAction('[PROJECTS] Filter Projects', props<{ params: ProjectsFilter }>());
    export const orderProjects = createAction('[PROJECTS] Order Projects', props<{ params: ProjectsOrder }>());
    export const pageProjects = createAction('[PROJECTS] Page Projects', props<{ params: ProjectsPagination }>());
    export const updatePagination = createAction('[PROJECTS] Update Pagination', props<{ params: ProjectsPagination }>());
    export const goToProjectDetails = createAction('[PROJECTS] Go To Project Details', props<{ projectId: string }>());
    export const backToProjects = createAction('[PROJECTS] Back To Projects');
    export const buildingValidateAddress = createAction(
        '[PROJECTS] Building Validate Address',
        props<{ projectFormValue: ProjectFormValue }>()
    );
    export const buildingValidateAddressSuccess = createAction(
        '[PROJECTS] Building Validate Address Success',
        props<BuildingLocationResponse>()
    );
}
