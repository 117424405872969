import { ProjectsOrderBy } from '@nx-customer-apps/shared/enums';
import { Column } from '@nx-customer-apps/shared/interfaces';

export const defaultProjectsPageSize = 20;

export const defaultProjectsPageIndex = 0;

export const defaultProjectsColumns: Column[] = [
    {
        title: 'PROJECT_LIST.NAME',
        name: 'projectName',
        flex: 2,
        orderBy: ProjectsOrderBy.ProjectName
    },
    {
        title: 'PROJECT_LIST.STATUS',
        name: 'projectStatus',
        flex: 1
    },
    {
        title: 'PROJECT_LIST.BUILDING_LOCATION',
        name: 'address',
        flex: 1
    },
    {
        title: 'PROJECT_LIST.CHANGED_ON',
        name: 'lastChangeDate',
        flex: 1,
        orderBy: ProjectsOrderBy.LastChangeDate
    },
    {
        title: 'PROJECT_LIST.FROM',
        name: 'lastChangeBy',
        flex: 1
    },
    {
        title: '',
        name: 'action'
    }
];
