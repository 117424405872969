import { Injectable } from '@angular/core';
import { CountryCode, GEGSystemType, RoutePaths, RouterLinks } from '@nx-customer-apps/shared/enums';
import { TranslateService } from '@ngx-translate/core';
import { GEGStore } from '../../state/geg/geg.store';
import { distinctUntilChanged, filter, first, forkJoin, map, Observable, withLatestFrom } from 'rxjs';
import { LodashService } from '@nx-customer-apps/shared/services';
import { HeatingEnergySourceState } from '@nx-customer-apps/shared/interfaces';
import { SystemItem } from '@nx-customer-apps/api-planning-projects';
import { SettingsStore } from '../../state/settings/settings.store';
import { EnvironmentService } from '../../services';
import { PARTNER_PORTAL_ROUTES } from '../../shared/utils';

@Injectable({
    providedIn: 'root'
})
export class GEGService {
    constructor(
        private translate: TranslateService,
        private gegStore: GEGStore,
        private settingsStore: SettingsStore,
        private environment: EnvironmentService
    ) {}

    public getSystemSuccessMessage(value: GEGSystemType.Current | GEGSystemType.New): string {
        const messageDictionary = {
            [GEGSystemType.Current]: 'GEG_SYSTEM_CHOICE.SYSTEM.SYSTEM_TYPE.SUCCESS_MESSAGES.CURRENT',
            [GEGSystemType.New]: 'GEG_SYSTEM_CHOICE.SYSTEM.SYSTEM_TYPE.SUCCESS_MESSAGES.NEW'
        };

        return this.translate.instant(messageDictionary[value]);
    }

    public getSystemChoiceBackLink(projectId: string): { link: string; label: string } {
        return this.backToProjectDetails(projectId);
    }

    public getNewSystemBackLink(projectId: string, systemType: GEGSystemType): { link: string; label: string } {
        if (systemType === GEGSystemType.Hybrid) {
            return { link: `/${RouterLinks.GEG}/${projectId}/${RoutePaths.EvaluatedSystem}`, label: 'NAVBAR.GEG_EVALUATED_SYSTEM' };
        }
        return { link: this.getSystemChoiceLink(projectId), label: 'NAVBAR.GEG_SYSTEM_CHOICE' };
    }

    public getCurrentSystemBackLink(projectId: string): { link: string; label: string } {
        return { link: this.getSystemChoiceLink(projectId), label: 'NAVBAR.GEG_SYSTEM_CHOICE' };
    }

    public getEvaluatedSystemBackLink(projectId: string): { link: string; label: string } {
        return this.backToProjectDetails(projectId);
    }

    public getSuggestedSystemBackLink(projectId: string, systemType: GEGSystemType): { link: string; label: string } {
        if (systemType === GEGSystemType.Hybrid) {
            return { link: `/${RouterLinks.GEG}/${projectId}/${RoutePaths.CurrentSystem}`, label: 'COMMON.BUTTONS.BACK' };
        }
        return { link: `/${RouterLinks.GEG}/${projectId}/${RoutePaths.EvaluatedSystem}`, label: 'COMMON.BUTTONS.BACK' };
    }

    public getSystemChoiceLink(projectId: string): string {
        return `/${RouterLinks.GEG}/${projectId}/${RoutePaths.SystemChoice}`;
    }

    public isGEGOfType(target: GEGSystemType, comparison: GEGSystemType): boolean {
        return target === comparison;
    }

    public getFAQUrl$(): Observable<string> {
        return forkJoin([
            this.settingsStore.countryCode$.pipe(filter(Boolean), first()),
            this.settingsStore.languageCode$.pipe(filter(Boolean), first())
        ]).pipe(
            map(([countryCode, languageCode]) => {
                return this.environment.getPartnerPortalUrl({
                    countryCodeSource: countryCode as CountryCode,
                    languageCodeSource: languageCode!,
                    path: PARTNER_PORTAL_ROUTES.GEG_CHECK
                });
            })
        );
    }

    /**
     * Reacting to every change of heating system breaks accordion performance.
     * Checking energy source and heating systems value changes helps to
     * keep accordions correct behavior when user reselects energy source
     * or navigates back from geg estimation screen.
     */
    public getRefreshedHeatingSystemsOnEnergySourceChange$(): Observable<SystemItem[] | undefined> {
        return this.gegStore.currentSystemHeatingSystems$.pipe(
            withLatestFrom(this.gegStore.currentSystemEnergySource$, (heatingSystems, energySource) => ({
                heatingSystems,
                energySource
            })),
            distinctUntilChanged((prev, curr) => {
                const getEnergySourceType = (state: { energySource?: HeatingEnergySourceState }) =>
                    state?.energySource?.selectedEnergySource.type;
                const getHeatGeneratorType = (state: { energySource?: HeatingEnergySourceState }) =>
                    state?.energySource?.selectedHeatGenerator;
                const isSameEnergySource = LodashService.isEqual(getEnergySourceType(prev), getEnergySourceType(curr));
                const isSameHeatGenerator = LodashService.isEqual(getHeatGeneratorType(prev), getHeatGeneratorType(curr));
                const isSameHeatingSystem = LodashService.isEqual(prev.heatingSystems, curr.heatingSystems);
                return isSameEnergySource && isSameHeatGenerator && isSameHeatingSystem;
            }),
            map(state => state.heatingSystems)
        );
    }

    private backToProjectDetails(projectId: string): { link: string; label: string } {
        return { link: `${RouterLinks.Project}${projectId}`, label: 'NAVBAR.PROJECT_DETAILS' };
    }
}
