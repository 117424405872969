<mat-form-field
    appearance="outline"
    [class.ca-highlight]="highlight"
    [class.mat-form-field-invalid]="isInvalid && isTouched"
    [ngClass]="question | formFieldStyles"
    subscriptSizing="dynamic"
    [hideRequiredMarker]="true"
    class="ca-form-field ca-text-field-dropdown ca-form-field-w-100"
>
    <mat-label *ngIf="question.label" [attr.for]="question.key">{{ question.label | translate }}</mat-label>
    <input
        matInput
        class="position-absolute ca-text-field-dropdown-input"
        [formControl]="textControl"
        [id]="question.key"
        [attr.data-testid]="question.key"
        [mask]="question.mask.pattern"
        [patterns]="$any(question).mask?.customPatterns"
        [decimalMarker]="$any(question).mask?.decimalMarker"
        [separatorLimit]="$any(question).mask?.separatorLimit"
        [dropSpecialCharacters]="($any(question).mask?.dropSpecialCharacters | isNil) ? true : $any(question).mask?.dropSpecialCharacters"
        (focus)="onFocus()"
    />

    <mat-select
        [formControl]="dropdownControl"
        [class]="question.key + '-test'"
        #dropdownRef
        [errorStateMatcher]="matcher"
        [disableOptionCentering]="true"
        [panelClass]="'ca-search-dropdown-panel-class ' + question.dropdownPanelClass"
        (selectionChange)="onDropdownSelect($event)"
    >
        <div *ngIf="loading; else optionsElement" class="d-flex justify-center align-items-center">
            <vp-spinner [diameter]="24"></vp-spinner>
        </div>
        <ng-template #optionsElement>
            <mat-option
                *ngFor="let option of question?.options; let index = index"
                [value]="option.key"
                [attr.data-testid]="question.key + index"
                [id]="question.key + index"
            >
                <span *ngIf="!option.customTemplate"> {{ option.key | translate }}</span>
                <ng-template vpViewHost *ngIf="option.customTemplate"></ng-template>
            </mat-option>
        </ng-template>
    </mat-select>
    <ca-icon *ngIf="highlight && !(isInvalid && isTouched)" matSuffix icon="check" class="ca-icon-s-16-p-0-f-16"></ca-icon>
    <mat-hint *ngIf="highlight && !(isInvalid && isTouched)">{{ question.conditionalHint | translate }}</mat-hint>
</mat-form-field>
<div *ngIf="isInvalid && isTouched" @slideDown class="vp-subscript position-relative">
    <div class="position-absolute vp-error-message">
        <vp-form-error-message>
            {{ errorMessage | translate }}
        </vp-form-error-message>
    </div>
</div>
