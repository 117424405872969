import { Directive, Input, Output, EventEmitter, HostListener, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs';
import { ConfirmConfig, ConfirmOutput } from '@nx-customer-apps/shared/interfaces';
import { ConfirmComponent } from './confirm.component';
import { ConfirmService } from './confirm.service';

@Directive({
    selector: '[vpConfirm]'
})
export class ConfirmDirective implements OnInit {
    private confirmRef: MatDialogRef<ConfirmComponent, ConfirmOutput>;

    @Input('vpConfirm') config: ConfirmConfig = {};

    @Input() vpConfirmContext: any;

    @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    @Output() closed: EventEmitter<void> = new EventEmitter<void>();

    constructor(private matDialog: MatDialog, private confirmService: ConfirmService) {}

    public ngOnInit(): void {
        if (this.config.uuid) {
            this.confirmService.register(this.config.uuid);
        }
    }

    @HostListener('click', ['$event'])
    public openDialog(e: MouseEvent): void {
        if (!this.config.disabled) {
            e.stopPropagation();

            this.confirmRef = this.matDialog.open(ConfirmComponent, {
                width: '520px',
                panelClass: 'ca-dialog',
                disableClose: true,
                autoFocus: false,
                data: { ...this.config, context: this.vpConfirmContext }
            });
            this.handleDialogClose();
        }
    }

    private handleDialogClose(): void {
        this.confirmRef
            .afterClosed()
            .pipe(
                tap(output => {
                    if (output?.confirm) {
                        this.confirm.emit();
                    } else if (output?.cancel) {
                        this.cancel.emit();
                    } else if (output?.close) {
                        this.closed.emit();
                    }

                    if (this.config.uuid) {
                        this.confirmService.confirm(this.config.uuid, output);
                    }
                })
            )
            .subscribe();
    }
}
