<ng-container [formGroup]="form">
    <div [formGroupName]="question.groupName" [ngClass]="question.styles">
        <ng-container
            *ngFor="let questionPart of question.questions; trackBy: trackByKey; let isLast = last; let isFirst = first"
            [ngSwitch]="questionPart.controlType"
        >
            <mat-form-field
                *ngSwitchCase="CONTROL_TYPES.Dropdown"
                subscriptSizing="dynamic"
                appearance="outline"
                class="ca-form-field"
                [class.with-label]="questionPart.label"
                [ngClass]="questionPart.styles"
                [hideRequiredMarker]="true"
            >
                <mat-label *ngIf="questionPart.label" [attr.for]="questionPart.key">{{ questionPart.label | translate }}</mat-label>
                <mat-select
                    [formControl]="countryControl"
                    [disableOptionCentering]="true"
                    [attr.data-testid]="questionPart.key"
                    panelClass="ca-dropdown-panel-class"
                >
                    <mat-option
                        *ngFor="let option of (questionPart | as : TYPES.Dropdown).options"
                        [value]="option.value"
                        [id]="questionPart.key + option.value"
                        [attr.data-testid]="questionPart.key + option.value"
                        >{{ option.key | translate }}</mat-option
                    >
                </mat-select>
            </mat-form-field>

            <mat-form-field
                subscriptSizing="dynamic"
                *ngSwitchCase="CONTROL_TYPES.Textbox"
                [hideRequiredMarker]="true"
                appearance="outline"
                class="ca-form-field"
                [class.ca-highlight]="highlights[questionPart.key]"
                [class.with-label]="questionPart.label"
                [class.with-wrapped-hint]="highlights[questionPart.key]"
                [class.with-wrapped-error-message]="isInvalid(questionPart) && isTouched(questionPart)"
                [class.mb-2]="!(isInvalid(questionPart) && isTouched(questionPart))"
                [ngClass]="questionPart.styles"
            >
                <mat-label *ngIf="questionPart.label" [attr.for]="questionPart.key">{{ questionPart.label | translate }}</mat-label>
                <input
                    [formControlName]="questionPart.key"
                    [placeholder]="(questionPart | as : TYPES.TextboxQuestion).placeholder | translate"
                    [id]="questionPart.key"
                    [attr.data-testid]="questionPart.key"
                    [type]="questionPart.type"
                    [mask]="(questionPart | as : TYPES.TextboxQuestion).mask.pattern"
                    [patterns]="$any(questionPart).mask?.customPatterns"
                    [decimalMarker]="$any(questionPart).mask?.decimalMarker"
                    [separatorLimit]="$any(questionPart).mask?.separatorLimit"
                    [dropSpecialCharacters]="
                        ($any(questionPart).mask?.dropSpecialCharacters | isNil) ? true : $any(questionPart).mask?.dropSpecialCharacters
                    "
                    [suffix]="
                        (questionPart | as : TYPES.TextboxQuestion).suffix
                            ? ' ' + ((questionPart | as : TYPES.TextboxQuestion).suffix | translate)
                            : ''
                    "
                    matInput
                />

                <mat-error *ngIf="isInvalid(questionPart) && isTouched(questionPart)">
                    <vp-form-error-message>
                        {{ getErrorMessage(questionPart) | translate }}
                    </vp-form-error-message>
                </mat-error>
                <ca-icon
                    *ngIf="highlights[questionPart.key] && !(isInvalid(questionPart) && isTouched(questionPart))"
                    icon="check"
                    matSuffix
                    class="ca-icon-s-16-p-0-f-16"
                ></ca-icon>
                <mat-hint *ngIf="highlights[questionPart.key] && !(isInvalid(questionPart) && isTouched(questionPart))">{{
                    (questionPart | as : TYPES.TextboxQuestion).conditionalHint | translate
                }}</mat-hint>
            </mat-form-field>

            <vp-checkbox
                class="w-100"
                [form]="formGroup"
                *ngSwitchCase="CONTROL_TYPES.Checkbox"
                [question]="questionPart | as : TYPES.CheckboxQuestion"
            ></vp-checkbox>

            <vp-search-dropdown
                class="w-100"
                [highlight]="highlights[questionPart.key]"
                [formGroup]="formGroup"
                [formControlName]="(questionPart | as : TYPES.SearchDropdown).key"
                *ngSwitchCase="CONTROL_TYPES.SearchDropdown"
                [question]="questionPart | as : TYPES.SearchDropdown"
                [minimalSearchValueLength]="1"
            ></vp-search-dropdown>
        </ng-container>
    </div>
</ng-container>
