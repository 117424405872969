<div class="d-flex flex-column align-items-center vp-gap-24 my-6 p-6">
    <div>
        <img width="360" src="/assets/images/error-403.svg" />
    </div>
    <div class="d-flex justify-content-center" data-testid="error-page-title">
        <strong class="mat-display-large mb-0">{{ 'COMMON.ERRORS.403.TITLE' | translate }}</strong>
    </div>
    <div class="d-flex flex-column align-items-center vp-gap-24">
        <div>{{ 'COMMON.ERRORS.403.CONTENT' | translate }}</div>
        <div>{{ 'COMMON.ERRORS.403.SALES_CONTACT' | translate }}</div>
    </div>
    <hr />
    <div class="d-flex flex-column align-items-center vp-gap-24 mt-4">
        <div>{{ 'COMMON.ERRORS.403.CONTACT' | translate }}</div>

        <div class="d-flex justify-content-center">
            <a href="https://live.viessmann.com/de/kontakt.html" data-testid="error-page-link">
                <button mat-stroked-button class="ca-button mt-auto">
                    <span class="button-title">{{ 'COMMON.ERRORS.403.FORM_BUTTON' | translate }}</span>
                    <ca-icon icon="arrow-outward" class="ca-icon-s"></ca-icon>
                </button>
            </a>
        </div>
    </div>
</div>
