import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Observable, tap, map, filter, mergeMap } from 'rxjs';
import { RoutePaths } from '@nx-customer-apps/shared/enums';
import { WindowService } from '@nx-customer-apps/shared/services';
import { AuthStore } from '../state/auth/auth.store';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService {
    private readonly nextRouteStorageKey: string = environment.storageKeys.nextRoute;

    constructor(private authStore: AuthStore, private router: Router, private windowService: WindowService) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        switch (route.routeConfig?.path) {
            case RoutePaths.LandingPage:
                return this.authStore.canRedirect$.pipe(
                    filter(Boolean),
                    mergeMap(() =>
                        this.authStore.isViplanDefault$.pipe(
                            tap(isDefaultUser => {
                                if (isDefaultUser) {
                                    this.navigateUser();
                                }
                            }),
                            map(isDefaultUser => !isDefaultUser)
                        )
                    )
                );
            default:
                return this.authStore.state$.pipe(
                    tap(() => {
                        this.setNextRoute(route);
                    }),
                    filter(state => state.canRedirect),
                    mergeMap(state =>
                        this.authStore.isViplanDefault$.pipe(
                            tap(isDefaultUser => {
                                // Redirect to landing page if user is not logged in.
                                if (!isDefaultUser) {
                                    this.router.navigate(['/']);
                                }
                            }),
                            map(isDefaultUser => {
                                if (state.userHasAccess) {
                                    this.windowService.sessionStorage.removeItem(this.nextRouteStorageKey);
                                    return !!isDefaultUser;
                                }
                                return false;
                            })
                        )
                    )
                );
        }
    }

    private setNextRoute(route: ActivatedRouteSnapshot): void {
        const targetRoute = route.url.map(url => `/${url.path}`).join('') || '';
        const queryParams = new HttpParams({ fromObject: route.queryParams }).toString();
        if (targetRoute) {
            this.windowService.sessionStorage.setItem(
                this.nextRouteStorageKey,
                queryParams ? `${targetRoute}?${queryParams}` : targetRoute
            );
        }
    }

    private navigateUser(): void {
        // Redirect to next route if exists or project list when user is logged in.
        const nextRoute = this.windowService.sessionStorage.getItem(this.nextRouteStorageKey);
        if (nextRoute) {
            const [route, search] = nextRoute.split('?');
            this.windowService.sessionStorage.removeItem(this.nextRouteStorageKey);
            if (search) {
                const searchParams = new URLSearchParams(search);
                const queryParams = Object.fromEntries(searchParams);
                this.router.navigate(route.split('/'), { queryParams });
            } else {
                this.router.navigate(nextRoute.split('/'));
            }
        } else {
            this.router.navigate([RoutePaths.Projects]);
        }
    }
}
