import { Injectable } from '@angular/core';
import { filter, first, map, withLatestFrom } from 'rxjs';
import { ProjectStore } from '../../state/project/project.store';
import { GEGFormOptionsService } from './geg-form-options.service';
import { GEGStore } from '../../state/geg/geg.store';
import { GEGFormService } from './geg-form.service';
import { FormKeys, GEGSystemType } from '@nx-customer-apps/shared/enums';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class GegSystemChoiceService {
    constructor(
        private projectStore: ProjectStore,
        private formOptionsService: GEGFormOptionsService,
        private translate: TranslateService,
        private formService: GEGFormService,
        private gegStore: GEGStore
    ) {}

    public questions$ = this.projectStore.project$.pipe(
        filter(Boolean),
        first(),
        withLatestFrom(this.gegStore.system$, (project, system) => ({ project, system })),
        map(({ system }) => {
            const formOptions = system ? this.formOptionsService.getSystemFormOptions({ prefill: system.value }) : undefined;
            return this.formService.getSystemQuestions(formOptions);
        })
    );

    public getSuccessMessage(formValue: { [FormKeys.System]: GEGSystemType.Current | GEGSystemType.New }): string {
        const messageDictionary = {
            [GEGSystemType.Current]: 'GEG_SYSTEM_CHOICE.SYSTEM.SYSTEM_TYPE.SUCCESS_MESSAGES.CURRENT',
            [GEGSystemType.New]: 'GEG_SYSTEM_CHOICE.SYSTEM.SYSTEM_TYPE.SUCCESS_MESSAGES.NEW'
        };
        return this.translate.instant(messageDictionary[formValue[FormKeys.System]]);
    }

    public isPrefillNeeded(): boolean {
        return true;
    }
}
