import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, iif, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthActions } from './auth.actions';
import { SettingsActions } from '../settings/settings.actions';

import { Router } from '@angular/router';
import { AuthService, EnvironmentService } from 'apps/viguide-planning/src/app/services';
import { RoutePaths } from '@nx-customer-apps/shared/enums';
import { GlobalSpinnerService } from '../../core';
import { UserResult } from '@nx-customer-apps/shared/interfaces';
import { AuthStore } from './auth.store';
import { UsersControllerService } from '@nx-customer-apps/api-planning-projects';
import { WindowService } from '@nx-customer-apps/shared/services';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private authStore: AuthStore,
        private authService: AuthService,
        private usersControllerService: UsersControllerService,
        private envService: EnvironmentService,
        private windowService: WindowService,
        private router: Router,
        private globalSpinnerService: GlobalSpinnerService
    ) {}

    public startAuthFlow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.startAuthFlow),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.LOADING'
                });
            }),
            switchMap(() => {
                // check url. token should be part of url after redirect from IAM
                const token = this.windowService.tokenFromUrl;
                if (token) {
                    // store token in local storage
                    this.windowService.localStorage.setItem(this.envService.csrfTokenLocalStorageKey, token);

                    return of(AuthActions.getMe());
                } else {
                    // if token was not found then try to find it in local storage
                    return of(AuthActions.getCsrfTokenFromStorage());
                }
            })
        )
    );

    public getCsrfTokenFromStorage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.getCsrfTokenFromStorage),
            switchMap(() => {
                const token = this.windowService.localStorage.getItem(this.envService.csrfTokenLocalStorageKey);
                if (token) {
                    // Check if token expired
                    const isTokenExpired = this.isTokenExpired(token);
                    if (!isTokenExpired) {
                        return of(AuthActions.getMe());
                    } else {
                        // Remove old token and fetch new one
                        this.windowService.localStorage.removeItem(this.envService.csrfTokenLocalStorageKey);
                        return of(AuthActions.getCsrfToken({ appId: this.envService.appId }));
                    }
                } else {
                    return of(AuthActions.getCsrfToken({ appId: this.envService.appId }));
                }
            })
        )
    );

    public getCsrfToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.getCsrfToken),
            switchMap(action =>
                // request new CSRF token from backend
                this.authService.getCsrfToken(action.appId).pipe(
                    map(data => AuthActions.getCsrfTokenSuccess({ token: data.token })),
                    catchError(() => {
                        this.globalSpinnerService.reset();
                        return of(AuthActions.getCsrfTokenError());
                    })
                )
            )
        )
    );

    public getCsrfTokenSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.getCsrfTokenSuccess),
            tap(action => {
                this.windowService.localStorage.setItem(this.envService.csrfTokenLocalStorageKey, action.token);
            }),
            switchMap(() => of(AuthActions.getMe()))
        )
    );

    public getMe$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.getMe),
            delay(0),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.LOADING'
                });
            }),
            switchMap(() =>
                this.authService.getLoggedInUserDetails().pipe(
                    map(data => AuthActions.getMeSuccess({ me: data })),
                    catchError(() => of(AuthActions.getMeError()))
                )
            )
        )
    );

    public getMeSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.getMeSuccess),
            map(({ me }) => {
                const isAllowed = this.authService.isViplanDefault(me) && !this.authService.isEndCustomer(me);
                return { me, isAllowed };
            }),
            tap(({ me, isAllowed }) => {
                if (!isAllowed) {
                    // Show 403 page for end customer or missing role
                    this.router.navigate([RoutePaths.Error403]);
                } else {
                    // Push an authorized user to Google Tag Manager
                    this.pushGtmEvent(me);
                }

                // Token refresh cleanup
                if (this.windowService.localStorage.getItem('token_refresh')) {
                    this.windowService.localStorage.removeItem('token_refresh');
                }
            }),
            switchMap(({ isAllowed }) =>
                iif(() => isAllowed && this.envService.validateAccess, of(AuthActions.validateAccess()), of(AuthActions.authorizeSuccess()))
            )
        )
    );

    public getMeError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.getMeError),
                tap(() => {
                    this.globalSpinnerService.reset();
                    this.router.navigate(['/']);
                })
            ),
        { dispatch: false }
    );

    public validateAccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.validateAccess),
            delay(0),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.LOADING'
                });
            }),
            switchMap(() =>
                this.usersControllerService.usersControllerValidateAccess().pipe(
                    map(access => AuthActions.validateAccessSuccess({ access })),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    public validateAccessSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.validateAccessSuccess),
            switchMap(action =>
                iif(() => action.access.userHasAccess, of(AuthActions.authorizeSuccess()), of(AuthActions.noSalesForceAccess()))
            )
        )
    );

    public initLocale$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.noSalesForceAccess, AuthActions.authorizeSuccess),
            withLatestFrom(this.authStore.me$, (_action, me) => me),
            map(me => {
                if (this.authService.isInstaller(me)) {
                    return `${me.languageCode.toLowerCase()}-${me.company.countryCode.toUpperCase()}`;
                }
                const [language, country] = me.locale.split('-');
                return `${language.toLowerCase()}-${country.toUpperCase()}`;
            }),
            switchMap(locale => [SettingsActions.initLocale({ locale }), SettingsActions.getSalesOrganizations()])
        )
    );

    public noSalesForceAccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.noSalesForceAccess),
                tap(() => {
                    this.globalSpinnerService.reset();
                    this.router.navigate([RoutePaths.ErrorNoAccess]);
                })
            ),
        { dispatch: false }
    );

    public logout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.logout),
                tap(() => {
                    this.clearStorage();
                    this.router.navigate(['/']);
                })
            ),
        { dispatch: false }
    );

    public clearToken$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.clearToken),
                tap(() => {
                    this.clearStorage();
                })
            ),
        { dispatch: false }
    );

    private isTokenExpired(token: string): boolean {
        return new JwtHelperService().isTokenExpired(token);
    }

    private clearStorage(): void {
        this.windowService.localStorage.removeItem(this.envService.csrfTokenLocalStorageKey);
    }

    /**
     * Google Tag Manager - push logged in user indentity type & environment name
     */
    private pushGtmEvent(me: UserResult): void {
        const identityType = this.authService.getIdentityType(me);
        this.windowService.nativeWindow.dataLayer = this.windowService.nativeWindow.dataLayer || [];
        this.windowService.nativeWindow.dataLayer.push({
            event: 'user_profile_loaded',
            identityType,
            userId: me.id,
            userProfileLoaded: true
        });
        this.windowService.nativeWindow.dataLayer.push({ environment: this.envService.name });
    }
}
