import { CountryCode } from '@nx-customer-apps/shared/enums';
import { EnumDictionary } from '../types';

export const supportedCountryLanguages: Partial<EnumDictionary<CountryCode, string[]>> = {
    [CountryCode.AT]: ['de'],
    [CountryCode.BE]: ['fr', 'nl'],
    [CountryCode.DE]: ['de', 'en'],
    [CountryCode.NL]: ['nl'],
    [CountryCode.FR]: ['fr'],
    [CountryCode.CH]: ['de', 'fr'],
    [CountryCode.CZ]: ['cs'],
    [CountryCode.SK]: ['sk'],
    [CountryCode.PL]: ['pl'],
    [CountryCode.ES]: ['es']
};
