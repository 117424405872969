import { Pipe, PipeTransform } from '@angular/core';
import { GegConfiguration } from '@nx-customer-apps/api-planning-projects';
import { GegRecommendationType } from '@nx-customer-apps/shared/enums';

@Pipe({
    name: 'gegAllowSubsidyCalculatorLink'
})
export class GegAllowSubsidyCalculatorLinkPipe implements PipeTransform {
    transform(gegConfiguration: GegConfiguration): boolean {
        return (
            !!gegConfiguration?.newSystem &&
            !!gegConfiguration?.result?.recommendations.every(
                recommendation => recommendation.recommendationType === GegRecommendationType.Info
            )
        );
    }
}
