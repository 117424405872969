import { createSelector } from '@ngrx/store';
import { AppState, AuthState } from '@nx-customer-apps/shared/interfaces';
import { LocaleUtils } from '@nx-customer-apps/shared/utils';
import { ApplicationRoles, CountryLanguageCodes, IdentityTypes, UserProperties } from '@nx-customer-apps/shared/enums';

export namespace AuthSelectors {
    export const authState = (state: AppState) => state.auth;

    export const getState = () => createSelector(authState, (state: AuthState) => state);

    export const getAuthenticating = () => createSelector(authState, (state: AuthState) => state.authenticating);

    export const getAuthenticatedState = () => createSelector(authState, (state: AuthState) => state.authenticated);

    export const getMe = () => createSelector(authState, (state: AuthState) => state.me);

    export const getUserId = () => createSelector(authState, (state: AuthState) => state?.me?.id);

    export const getCanRedirect = () => createSelector(authState, (state: AuthState) => state.canRedirect);

    export const isInstaller = () =>
        createSelector(authState, (state: AuthState) => {
            const identityType = state.me?.properties?.find(property => property.name === UserProperties.IdentityType);
            return !!(identityType && identityType.value === IdentityTypes.Installer);
        });

    export const getUserName = () =>
        createSelector(authState, (state: AuthState) => {
            const firstName = state?.me?.name.firstName;
            const lastName = state?.me?.name.familyName;
            return `${firstName} ${lastName}`;
        });

    export const isViplanDefault = () =>
        createSelector(
            authState,
            (state: AuthState) =>
                state.me?.applicationRoles?.includes(ApplicationRoles.Default) ||
                state.me?.applicationRoles?.includes(ApplicationRoles.NevisDefault)
        );

    export const getCountryLanguagePairs = () =>
        createSelector(authState, (state: AuthState) => {
            const pairs = Object.entries(CountryLanguageCodes).map(([key, locale]) => ({ key, locale }));

            try {
                const identityType = state.me?.properties?.find(property => property.name === UserProperties.IdentityType);
                const isInstallerIdentity = !!(identityType && identityType.value === IdentityTypes.Installer);
                if (!isInstallerIdentity) {
                    return pairs;
                }
                const localeSource = `${state.me?.languageCode}-${state.me?.company.countryCode}`;
                let locale = LocaleUtils.parseLocale(localeSource);
                const isLocaleSupported = LocaleUtils.supportedLocales.includes(locale);
                if (!isLocaleSupported) {
                    locale = LocaleUtils.defaultLocale;
                }
                const [_, countryCode] = locale.split('-');

                return pairs.filter(pair => pair.locale.includes(countryCode));
            } catch (error) {
                return pairs.filter(pair => pair.locale.includes(LocaleUtils.defaultCountryCode));
            }
        });
}
