<div class="d-flex justify-content-between pt-4 pb-0" mat-dialog-title data-testid="confirm-dialog-title">
    <h3 *ngIf="title">
        {{ title }}
    </h3>
    <h3 *ngIf="titleElement">
        <ng-container *ngTemplateOutlet="titleElement; context: { $implicit: context }"></ng-container>
    </h3>
    <button
        mat-icon-button
        class="ca-button close-button ms-auto d-flex justify-content-center"
        data-testid="confirm-dialog-close-btn"
        (click)="close.emit()"
    >
        <ca-icon icon="cross"></ca-icon>
    </button>
</div>
