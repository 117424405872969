import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, EMPTY, switchMap, catchError, tap, withLatestFrom } from 'rxjs';
import { CalculationsControllerService } from '@nx-customer-apps/api-planning-projects';
import { GEGEvaluationActions } from './geg-evaluation.actions';
import { Router } from '@angular/router';
import { RoutePaths, RouterLinks } from '@nx-customer-apps/shared/enums';
import { ProjectStore } from '../project/project.store';

@Injectable()
export class GEGEvaluationEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private projectStore: ProjectStore,
        private calculationsControllerService: CalculationsControllerService
    ) {}

    public evaluateAlternativeGEG$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GEGEvaluationActions.evaluateAlternativeGEG),
            switchMap(({ body }) =>
                this.calculationsControllerService.calculationsControllerGegEvaluation({ body }).pipe(catchError(() => EMPTY))
            ),
            map(evaluation => GEGEvaluationActions.evaluateAlternativeGEGSuccess({ evaluation }))
        )
    );

    public evaluateAlternativeGEGSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GEGEvaluationActions.evaluateAlternativeGEGSuccess),
                withLatestFrom(this.projectStore.project$, (action, project) => ({ action, project })),
                tap(({ project }) => {
                    this.router.navigate([RouterLinks.GEG, project!.id, RoutePaths.AlternativeSystem]);
                })
            ),
        { dispatch: false }
    );

    public evaluateReviewedGEG$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GEGEvaluationActions.evaluateReviewedGEG),
            switchMap(({ body }) =>
                this.calculationsControllerService.calculationsControllerGegEvaluation({ body }).pipe(catchError(() => EMPTY))
            ),
            map(evaluation => GEGEvaluationActions.evaluateReviewedGEGSuccess({ evaluation }))
        )
    );
}
