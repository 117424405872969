import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CountryCode, ExternalTool } from '@nx-customer-apps/shared/enums';
import { HttpParams } from '@angular/common/http';
import { WindowService } from '@nx-customer-apps/shared/services';
import { RemoteConfig } from '@nx-customer-apps/shared/interfaces';

declare function require(moduleName: string): any;

const { version: appVersion } = require('../../../../../package.json');

// ignoring coverage for that class
/* istanbul ignore next */
@Injectable()
export class EnvironmentService {
    constructor(private windowService: WindowService) {}

    public get name(): string {
        return environment.environment;
    }

    public get hotJarEnabled(): boolean {
        return environment.hotJarEnabled;
    }

    public get version(): string {
        return appVersion;
    }

    public get baseUrl(): string {
        return environment.baseUrl;
    }

    public get production(): boolean {
        return environment.production;
    }

    public get validateAccess(): boolean {
        return environment.validateAccess;
    }

    public get applicationName(): string {
        return environment.applicationName;
    }

    public get lockProjectIdStorageKey(): string {
        return environment.storageKeys.lockProjectId;
    }

    public get nextAttemptLockDelay(): number {
        return environment.projectLockConfig.nextAttemptLockDelay;
    }

    public get verifyLockPollingInterval(): number {
        return environment.projectLockConfig.verifyLockPollingInterval;
    }

    public get disableProjectLocking(): boolean {
        return environment.projectLockConfig.disabled;
    }

    public get localeLocalStorageKey(): string {
        return environment.storageKeys.locale;
    }

    public get onboardingLocalStorageKey(): string {
        return environment.storageKeys.onboarding;
    }

    public get appId(): string {
        return environment.csrfTokenConfig.appId;
    }

    public get accountApiUrl(): string {
        return environment.accountApiUrl;
    }

    public get requestLoginUrl(): string {
        return environment.samlConfig.requestLoginUrl;
    }

    public get csrfTokenLocalStorageKey(): string {
        return environment.csrfTokenConfig.localStorageKey;
    }

    public get remoteConfigAppId(): string {
        return environment.remoteConfig.appId;
    }

    public get remoteConfigUrl(): string {
        return environment.remoteConfig.remoteConfigUrl;
    }

    public get remoteConfigEnabled(): boolean {
        return environment.remoteConfig.enabled;
    }

    public get defaultAppConfig(): RemoteConfig {
        return environment.remoteConfig.defaultConfig;
    }

    /**
     * A set of special environment related headers injected into each Project Plannings API request.
     */
    public get environmentHeaders(): { [headerName: string]: string } {
        return environment.environmentHeaders;
    }

    public getExternalUrl(externalTool: ExternalTool): string {
        const externalToolLink = environment.externalTools[externalTool];
        if (externalToolLink) {
            return externalToolLink;
        }

        throw new Error(`Missing URL definition for external tool ${externalTool}.`);
    }

    public getLoginUrl(): string {
        let params = new HttpParams();
        params = params.append('appId', this.appId);
        params = params.append('ForgotUsername', `${this.accountApiUrl}/forgot-username`);
        params = params.append('RegistrationLink', `${this.accountApiUrl}/register?redirect=${this.windowService.registrationRedirectUrl}`);
        params = params.append('iambgimg', environment.globalHeader.iamBackgroundImage);
        return `${this.requestLoginUrl}?${params.toString()}`;
    }

    public getPartnerPortalUrl(route?: { countryCodeSource: CountryCode; languageCodeSource: string; path: string }): string {
        if (!route) {
            return environment.partnerPortalBaseUrl;
        }
        const { countryCodeSource, languageCodeSource, path } = route;
        const countryCode = countryCodeSource.toLocaleLowerCase();
        const languageCode = languageCodeSource.toLocaleLowerCase();
        return `${environment.partnerPortalBaseUrl}/${countryCode}/${languageCode}/${path}`;
    }

    public getRegisterUrl(): string {
        let params = new HttpParams();
        params = params.append('hideNoCompanyNumberOption', 'true');
        params = params.append('redirect', environment.registerRedirectUrl);
        return `${environment.accountApiUrl}/register?${params.toString()}`;
    }
}
