import { ProjectStatus } from '@nx-customer-apps/shared/enums';

export const isReadOnly = (projectStatus: ProjectStatus, options?: { excludeMigrated?: boolean }): boolean => {
    if (options?.excludeMigrated) {
        return [ProjectStatus.Closed, ProjectStatus.Archived, ProjectStatus.Completed].includes(projectStatus);
    }
    return [
        ProjectStatus.Closed,
        ProjectStatus.Archived,
        ProjectStatus.Completed,
        ProjectStatus.Migrated,
        ProjectStatus.MigratedClosed
    ].includes(projectStatus);
};
