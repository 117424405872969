import { createReducer, on } from '@ngrx/store';
import { ProjectAccessState } from '@nx-customer-apps/shared/interfaces';
import { ProjectAccessActions } from './project-access.actions';
import { LockResult } from '@nx-customer-apps/shared/enums';

export const initialState: ProjectAccessState = {
    projectId: null,
    lockResult: null,
    verifyResult: null,
    pending: false,
    showExpiredLockSessionMessage: false,
    invokeBeforeUnload: true,
    otherSessionExists: false
};

export const ProjectAccessReducer = createReducer(
    initialState,
    on(ProjectAccessActions.showExpiredLockSessionMessage, state => {
        return {
            ...state,
            showExpiredLockSessionMessage: true
        };
    }),
    on(ProjectAccessActions.skipProjectUnlockBeforeUnload, state => {
        return {
            ...state,
            invokeBeforeUnload: false
        };
    }),
    on(ProjectAccessActions.hideExpiredLockSessionMessage, state => {
        return {
            ...state,
            showExpiredLockSessionMessage: false
        };
    }),
    on(ProjectAccessActions.lockProject, state => {
        return {
            ...state,
            pending: true,
            otherSessionExists: false
        };
    }),
    on(ProjectAccessActions.lockProjectSuccess, (state, resp) => {
        return {
            ...state,
            pending: false,
            projectId: resp.projectId,
            lockedByUserId: resp.lockedByUserId,
            lockStartDate: resp.lockStartDate,
            lockResult: resp.result as LockResult
        };
    }),
    on(ProjectAccessActions.nextAttemptProjectLockResult, (state, resp) => {
        return {
            ...state,
            pending: false,
            projectId: resp.projectId,
            lockedByUserId: resp.lockedByUserId,
            lockStartDate: resp.lockStartDate,
            lockResult: resp.result as LockResult
        };
    }),
    on(ProjectAccessActions.nextAttemptProjectLockSuccess, (state, { otherSessionExists }) => {
        return {
            ...state,
            otherSessionExists
        };
    }),
    on(ProjectAccessActions.verifyProjectLockSuccess, (state, resp) => {
        return {
            ...state,
            projectId: resp.projectId,
            lockedByUserId: resp.lockedByUserId,
            verifyResult: resp.result as LockResult
        };
    }),
    on(ProjectAccessActions.unlockProjectSuccess, state => {
        return {
            ...state,
            projectId: null,
            lockedByUserId: null,
            lockResult: null,
            verifyResult: null,
            pending: false
        };
    })
);
