import { Directive, HostListener, Input } from '@angular/core';
import { GtmEvent } from '@nx-customer-apps/shared/interfaces';
import { GoogleTagManagerService } from '@nx-customer-apps/shared/services';

@Directive({
    selector: '[vpGtmEvent]'
})
export class GtmEventDirective {
    @Input('vpGtmEvent') event: GtmEvent;

    @HostListener('click') onClick() {
        if (this.event?.event) {
            this.gtmService.pushEvent(this.event);
        }
    }

    constructor(private gtmService: GoogleTagManagerService) {}
}
