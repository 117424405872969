import { createReducer, on } from '@ngrx/store';
import { ProjectState } from '@nx-customer-apps/shared/interfaces';
import { ProjectActions } from './project.actions';
import { ProjectStatus } from '@nx-customer-apps/shared/enums';

export const initialState: ProjectState = {};

export const projectReducer = createReducer(
    initialState,
    on(
        ProjectActions.getProjectSuccess,
        ProjectActions.migrationVerificationSuccess,
        ProjectActions.deleteHeatloadSuccess,
        ProjectActions.selectHeatloadSuccess,
        ProjectActions.saveHeatloadSuccess,
        ProjectActions.saveHeatPumpIntermediarySuccess,
        (state, action) => {
            return {
                ...state,
                selectedProject: action.project,
                addressCollision: false
            };
        }
    ),
    on(ProjectActions.resetProject, () => {
        return {
            ...initialState
        };
    }),
    on(ProjectActions.createProject, ProjectActions.updateProject, state => {
        return {
            ...state,
            addressCollision: false
        };
    }),
    on(ProjectActions.addressCollision, state => {
        return {
            ...state,
            addressCollision: true
        };
    }),
    on(ProjectActions.closeProjectSuccess, ProjectActions.completeProjectSuccess, (state, { response }) => {
        return {
            ...initialState,
            selectedProject: {
                ...state.selectedProject!,
                projectStatus: response.projectStatus
            }
        };
    })
);
