import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStore } from 'apps/viguide-planning/src/app/state/auth/auth.store';
import { AuthService, EnvironmentService } from 'apps/viguide-planning/src/app/services';
import { LodashService, SnackbarService, WindowService } from '@nx-customer-apps/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { RoutePaths } from '@nx-customer-apps/shared/enums';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
    private userId: string;
    private queryParams: { [key: string]: any };

    private errorHandlers: { [key: number]: (err: HttpErrorResponse, req: any, next: any) => Observable<any> | Observable<never> } = {
        401: this.handle401Error.bind(this),
        404: this.handle404Error.bind(this),
        452: (err: HttpErrorResponse) => {
            /**
             * 452 error => must be or must not be combined case.
             * error message will be provided in details
             */
            return throwError(() => err);
        }
    };

    constructor(
        private authStore: AuthStore,
        private envService: EnvironmentService,
        private authService: AuthService,
        private windowService: WindowService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private snackbarService: SnackbarService,
        private injector: Injector
    ) {
        this.authStore.userId$.subscribe((userId: string) => (this.userId = userId));
        this.activatedRoute.queryParams.subscribe(params => (this.queryParams = params));
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        request = request.clone();
        const request$ = next.handle(request);
        return request$.pipe(
            catchError((res: HttpErrorResponse) => {
                this.handleNotUnauthErrors(res, request);
                this.handleUnauthorizedError(res);

                let handler;
                const error = <HttpErrorResponse>res;

                if (LodashService.get(res, 'error.error.code')) {
                    // MW custom error
                    handler = this.errorHandlers[res.error.error.code];
                } else {
                    // other errors
                    handler = this.errorHandlers[error.status];
                }

                if (handler) {
                    return handler(error, request, next);
                } else {
                    return throwError(() => error);
                }
            })
        );
    }

    private get translate() {
        // Grabbing TranslateService by injector to avoid CI (circular dependency) issue
        return this.injector.get(TranslateService);
    }

    private removeOldToken(): void {
        this.windowService.localStorage.removeItem(this.envService.csrfTokenLocalStorageKey);
    }

    private setToken(token: string, request: { url?: string; clone?: any }): HttpRequest<any> {
        this.windowService.localStorage.setItem(this.envService.csrfTokenLocalStorageKey, token);
        return request.clone({
            setHeaders: {
                Authorization: `CSRF ${token}`
            }
        });
    }

    private handle401Error(err: HttpErrorResponse, request: { url: string }, next: HttpHandler) {
        // 401 on /saml/csrf endpoint means no valid session -> redirect to landing page
        if (request.url.endsWith('/saml/csrf')) {
            // if user was already logged in and the session expires we navigate to IAM page to refresh it
            if (this.userId) {
                this.windowService.localStorage.setItem('token_refresh', 'true');
                this.windowService.changeHref(this.envService.getLoginUrl());

                // if app-link param is present in url, navigate to IAM page to refresh session
            } else if (!LodashService.isNil(this.queryParams['app-link'])) {
                this.windowService.changeHref(this.envService.getLoginUrl());
            } else {
                // otherwise go to landing page
                this.authStore.logout();
            }
            return throwError(() => err);
        }

        this.removeOldToken();
        return this.authService.getCsrfToken(this.envService.appId).pipe(
            switchMap(data => {
                return next.handle(this.setToken(data.token, request));
            }),
            catchError(csrfErr => {
                this.authStore.logout();
                return throwError(() => csrfErr);
            })
        );
    }

    private handle404Error(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler) {
        // 404 on lock or unlock already deleted project - redirect to 404 not found page
        if (request.url.endsWith('/lock') || request.url.endsWith('/unlock')) {
            this.router.navigate([RoutePaths.Error404]);
            return throwError(() => err);
        }
        return next.handle(request);
    }

    private handleNotUnauthErrors(err: HttpErrorResponse, request: HttpRequest<any>): void {
        if (err.status === 401 || err.status === 403) {
            return;
        }

        if (err.status === 404 && (request.url.endsWith('/lock') || request.url.endsWith('/unlock'))) {
            return;
        }

        const status = err.error?.status || err.status;
        const messageKey = 'COMMON.ERRORS.SOMETHING_WRONG';
        let message: string;
        let duration = 5000;

        switch (status) {
            case 422:
                message = this.translate.instant(err.error?.key || messageKey);
                duration = 0;
                break;

            default:
                message = this.translate.instant(messageKey);
                break;
        }

        const action = this.translate.instant('COMMON.BUTTONS.GOT_IT');
        this.snackbarService.showInfo(message, {
            duration,
            action
        });
    }

    private handleUnauthorizedError(err: HttpErrorResponse): void {
        if (err.status === 403) {
            this.router.navigate([RoutePaths.Error403]);
        }
    }
}
