import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions } from './auth.actions';
import { AuthState } from '@nx-customer-apps/shared/interfaces';
import { environment } from '../../../environments/environment';

export const initialAuthState: AuthState = {
    canRedirect: false,
    authenticating: false,
    authenticated: null,
    authorizing: false,
    authorized: null,
    me: null,
    flowDone: false,
    userHasAccess: !environment.validateAccess
};

const reducer = createReducer(
    initialAuthState,
    on(AuthActions.startAuthFlow, () => ({
        ...initialAuthState,
        authenticating: true
    })),

    on(AuthActions.getCsrfTokenSuccess, state => ({
        ...state,
        authenticating: false,
        authenticated: true
    })),

    on(AuthActions.getCsrfTokenError, state => ({
        ...state,
        authenticating: false,
        authenticated: false,
        canRedirect: true
    })),

    on(AuthActions.getMe, state => ({
        ...state,
        authorizing: true
    })),

    on(AuthActions.getMeSuccess, (state, { me }) => ({
        ...state,
        me,
        canRedirect: !environment.validateAccess
    })),

    on(AuthActions.getMeError, state => ({
        ...state,
        authenticating: false,
        authenticated: false,
        authorizing: false,
        authorized: false,
        canRedirect: true
    })),

    on(AuthActions.authorizeSuccess, state => ({
        ...state,
        authenticating: false,
        authenticated: true,
        authorizing: false,
        authorized: true,
        flowDone: true
    })),

    on(AuthActions.noSalesForceAccess, state => ({
        ...state,
        authenticating: false,
        authenticated: true,
        authorizing: false,
        authorized: false,
        flowDone: true
    })),

    on(AuthActions.validateAccessSuccess, (state, { access }) => ({
        ...state,
        userHasAccess: access.userHasAccess,
        canRedirect: true
    })),

    on(AuthActions.logout, () => ({
        ...initialAuthState,
        authenticated: false
    }))
);

export function authReducer(state: AuthState | undefined, action: Action) {
    return reducer(state, action);
}
