import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { WindowService } from '@nx-customer-apps/shared/services';

@Component({
    selector: 'vp-jumbotron',
    templateUrl: './jumbotron.component.html',
    styleUrls: ['./jumbotron.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JumbotronComponent implements OnInit {
    public visible: boolean;

    @Input() public closable: boolean;
    @Input() public name: string;
    @Input() public title: string;
    @Input() public imageSrc: string;

    private localStorageKey: string;

    private readonly hiddenState: string = 'hidden';
    private readonly keyPrefix: string = 'jumbotron-';

    constructor(private windowService: WindowService) {}

    public ngOnInit(): void {
        this.localStorageKey = this.keyPrefix + this.name;
        const state = this.windowService.localStorage.getItem(this.localStorageKey);
        this.visible = state !== this.hiddenState;
    }

    public close(): void {
        this.windowService.localStorage.setItem(this.localStorageKey, this.hiddenState);
        this.visible = false;
    }
}
