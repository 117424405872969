import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MultiTextbox, TextboxQuestion } from '@nx-customer-apps/shared/utils';

@Component({
    selector: 'vp-multi-textbox',
    templateUrl: './multi-textbox.component.html'
})
export class MultiTextboxComponent {
    @Input() question: MultiTextbox;
    @Input() form: FormGroup;

    public trackByKey(index: number, item: { key: string }): string {
        return item.key;
    }

    public get formGroup(): FormGroup {
        return this.form.get(this.question.groupName) as FormGroup;
    }

    public getControl(key: string): FormControl {
        return this.formGroup?.get(key) as FormControl;
    }

    public isInvalid(textboxQuestion: TextboxQuestion): boolean {
        return this.getControl(textboxQuestion.key)?.invalid && this.getControl(textboxQuestion.key)?.touched;
    }

    public errorMessage(textboxQuestion: TextboxQuestion): string {
        const errorMessage = this.getControl(textboxQuestion.key)?.errors?.['message'];
        return errorMessage || textboxQuestion.defaultErrorMessage;
    }
}
