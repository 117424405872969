import { ControlTypes } from '@nx-customer-apps/shared/enums';
import { QuestionOptions } from '@nx-customer-apps/shared/interfaces';
import { QuestionBase } from './question-base.model';

export class CheckboxQuestion extends QuestionBase<boolean> {
    override controlType = ControlTypes.Checkbox;
    constructor(options: QuestionOptions<boolean>) {
        super(options);
        this.value = options.value || false;
    }
}
