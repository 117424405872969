import { ControlTypes } from '@nx-customer-apps/shared/enums';
import { QuestionGroupOptions } from '@nx-customer-apps/shared/interfaces';
import { CheckboxQuestion } from './checkbox.model';
import { QuestionGroupBase } from './question-group-base.model';

export class CheckboxSet extends QuestionGroupBase<CheckboxQuestion[]> {
    override controlType = ControlTypes.CheckboxSet;
    constructor(options: QuestionGroupOptions<CheckboxQuestion[]>) {
        super(options);
    }
}
