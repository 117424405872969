import { Injectable } from '@angular/core';
import { withLatestFrom, map, Observable, filter, first, merge } from 'rxjs';
import { HeatloadPrefillService } from './heatload-prefill.service';
import { HeatloadStore } from '../../state/heatload/heatload.store';
import { FormName, HeatLoadCalculationMethod, SuccessMessages } from '@nx-customer-apps/shared/enums';
import { HeatloadFormService } from './heatload-form.service';
import { ProjectStore } from '../../state/project/project.store';
import { getSelectedSourceFromGenerators } from '../../shared/utils/selected-energy-source.helper';
import { HeatGeneratorFormValue } from '@nx-customer-apps/shared/interfaces';
import { HeatGeneratorInformation } from '@nx-customer-apps/api-planning-projects';
import { HeatloadFormOptionsService } from './heatload-form-options.service';
import { ActivatedRoute } from '@angular/router';
import { HeatloadPrefillMapper } from '../../services';
import { HeatloadService } from './heatload.service';
import { AccordionService } from '../../shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class EnergySourceService implements AccordionService {
    constructor(
        private heatloadService: HeatloadService,
        private heatloadPrefillService: HeatloadPrefillService,
        private prefillMapper: HeatloadPrefillMapper,
        private heatloadFormOptionsService: HeatloadFormOptionsService,
        private heatloadStore: HeatloadStore,
        private projectStore: ProjectStore,
        private route: ActivatedRoute,
        private heatloadFormService: HeatloadFormService
    ) {}

    public questions$ = this.heatloadStore.heatGenerators$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatloadStore.cache$),
        map(([heatGenerators, project, cache]) => ({
            heatGenerators,
            project,
            cache
        })),
        map(state => {
            if (!state.heatGenerators) {
                return null;
            }

            const projectPrefill = this.prefillMapper.projectToEnergySourcePrefill(state.project);
            const cachePrefill = this.prefillMapper.stateToEnergySourcePrefill(state.cache?.heatingEnergySource);
            const options = this.heatloadFormOptionsService.getEnergySourceFormOptions({
                prefill: cachePrefill || projectPrefill,
                heatGenerators: state.heatGenerators
            });
            return this.heatloadFormService.getEnergySourceQuestions(options);
        })
    );

    public getSuccessMessage(generators: HeatGeneratorInformation[], formValue: HeatGeneratorFormValue): string {
        const energySource = getSelectedSourceFromGenerators(generators, formValue.heatGenerator, formValue.specificEnergySource);
        return `${SuccessMessages.HeatingEnergySource}.${energySource.type}`;
    }

    public isPrefillNeeded(): boolean {
        return this.heatloadService.isRecalculation() && !this.heatloadPrefillService.isPrefilled(FormName.EnergySource);
    }

    public afterPrefill(): void {
        this.heatloadPrefillService.setAsPrefilled(FormName.EnergySource);
    }

    public isExpanded$ = merge(this.isSubmitedCalculationMethodSameValue$(), this.isPrefillCompletedByConsumption$());

    public isSubmitedCalculationMethodSameValue$(): Observable<boolean> {
        return this.heatloadStore.sameValueSubmited$.pipe(
            map(
                form =>
                    form?.formName === FormName.CalculationMethod &&
                    form.value.methods.standardMethod === HeatLoadCalculationMethod.Consumption
            )
        );
    }

    public isPrefillCompletedByConsumption$(): Observable<boolean> {
        return this.heatloadPrefillService.state$.pipe(
            map(prefillState => {
                const lastAccordionToBePrefilled = FormName.HeatingDistribution;
                const isRecalculation = this.route.firstChild?.firstChild?.snapshot.queryParamMap.get('method');
                return prefillState[lastAccordionToBePrefilled].isPrefilled && !!isRecalculation;
            }),
            filter(Boolean),
            first()
        );
    }
}
