import { Pipe, PipeTransform } from '@angular/core';
import { MessageType } from '@nx-customer-apps/shared/enums';

@Pipe({
    name: 'messageTypeToIcon'
})
export class MessageTypeToIconPipe implements PipeTransform {
    transform(messageType: MessageType): string {
        switch (messageType) {
            case MessageType.Error:
                return 'cross-circle';
            case MessageType.Success:
                return 'check-default';
            case MessageType.Warning:
                return 'exclamation';
            case MessageType.Info:
            default:
                return 'info';
        }
    }
}
