import { Injectable } from '@angular/core';
import { GegSystem } from '@nx-customer-apps/api-planning-projects';
import { HeatGeneratorType, HeatingSystemType } from '@nx-customer-apps/shared/enums';
import {
    gegSystemCondensingBoilerKey,
    gegSystemTranslationPrefix,
    gegSystemHighTempBoilerKey,
    gegSystemLowTempBoilerKey,
    gegSystemNonCondensingBoilerKey,
    gegSystemsTranslationKeys
} from '../constants';

@Injectable({
    providedIn: 'root'
})
export class GegSystemTranslationsResolverService {
    /**
     * Resolves the GEG module system structure into single Phrase key, used to
     * display translation of the system full name.
     * @param system The GEG module system that will be resolved into Phrase key string.
     */
    public resolve(system: GegSystem): string {
        switch (system.heatGeneratorType) {
            case HeatGeneratorType.GasBoiler:
            case HeatGeneratorType.WoodStove:
                return gegSystemTranslationPrefix + this.getBoilerKey(system);

            case HeatGeneratorType.HeatPump:
                return gegSystemTranslationPrefix + this.getHeatPumpKey(system);

            case HeatGeneratorType.OilBoiler:
            case HeatGeneratorType.ElectricHeater:
            case HeatGeneratorType.SolarThermal:
            case HeatGeneratorType.DistrictHeating:
                return gegSystemTranslationPrefix + this.getSpecialHeatGeneratorKey(system);

            default:
                throw new Error(`The heat generator type "${system.heatGeneratorType}" is unknown and can't be processed.`);
        }
    }

    /**
     * Gets a key for one of the devices: GAS_BOILER, WOOD_STOVE.
     * @param system The GEG module system that will be resolved into Phrase key string.
     * @private
     */
    private getBoilerKey(system: GegSystem): string {
        const deviceType: string = gegSystemsTranslationKeys[system.heatGeneratorType];
        const energySource: string = gegSystemsTranslationKeys[system.energySource];
        let heatingSystemType: string = '_';
        let lowTemperature: string = '';

        heatingSystemType +=
            system.heatingSystemType === HeatingSystemType.CondensingBoiler
                ? gegSystemCondensingBoilerKey
                : gegSystemNonCondensingBoilerKey;

        if (system.isLowTemperature !== undefined && system.isLowTemperature !== null) {
            lowTemperature = '_';
            lowTemperature += system.isLowTemperature ? gegSystemLowTempBoilerKey : gegSystemHighTempBoilerKey;
        }

        if (system.heatingSystemType === HeatingSystemType.CondensingBoiler && lowTemperature != '') {
            // this is not supported now, but let's get prepared for this combination, when
            // isLowTemperature option is available in condensing boiler
            return `${deviceType}_${energySource}${heatingSystemType}${lowTemperature}`;
        } else if (system.heatingSystemType !== HeatingSystemType.CondensingBoiler && lowTemperature != '') {
            heatingSystemType = ''; // not needed when temperature provided
        }
        return `${deviceType}_${energySource}${heatingSystemType}${lowTemperature}`;
    }

    /**
     * Gets a key for heat pump only. In the future heat pump keys may be created
     * in more complicated way.
     * @param system The GEG module system that will be resolved into Phrase key string.
     * @private
     */
    private getHeatPumpKey(system: GegSystem): string {
        const deviceType: string = gegSystemsTranslationKeys[system.heatGeneratorType];
        const heatingSystemType: string = gegSystemsTranslationKeys[system.heatingSystemType];
        const heatPumpOperatingMode: string = gegSystemsTranslationKeys[system.heatPumpOperatingMode!];
        return heatPumpOperatingMode ? `${deviceType}_${heatingSystemType}_${heatPumpOperatingMode}` : `${deviceType}_${heatingSystemType}`;
    }

    /**
     * Shorter key for devices: ELECTRIC_HEATER, SOLAR_THERMAL, DISTRICT_HEATING, OIL_BOILER.
     * @param system The GEG module system that will be resolved into Phrase key string.
     * @private
     */
    private getSpecialHeatGeneratorKey(system: GegSystem): string {
        // NOTE
        //     Some heat generators are very specific and use one naming for
        //     HeatGeneratorType, EnergySource and HeatingSystemType, for
        //     that cases, the keys can be simplified as for heat pumps.
        //
        const deviceType: string = gegSystemsTranslationKeys[system.heatGeneratorType];
        return `${deviceType}`;
    }
}
