import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ControlTypes, FormKeys } from '@nx-customer-apps/shared/enums';
import { FormFieldHighlights } from '@nx-customer-apps/shared/interfaces';
import { Addressbox, AddressSubquestion, TypesHTMLHelper } from '@nx-customer-apps/shared/utils';

@Component({
    selector: 'vp-addressbox',
    templateUrl: './addressbox.component.html'
})
export class AddressboxComponent {
    @Input() question: Addressbox;
    @Input() highlights: FormFieldHighlights = {};
    @Input() form: FormGroup;
    readonly CONTROL_TYPES: typeof ControlTypes = ControlTypes;
    readonly TYPES = TypesHTMLHelper;

    public trackByKey(index: number, item: { key: string }): string {
        return item.key;
    }

    public get formGroup(): FormGroup {
        return this.form.get(this.question.groupName) as FormGroup;
    }

    public get countryControl(): FormControl {
        return this.formGroup.get(FormKeys.Country) as FormControl;
    }

    public getControl(key: string): FormControl {
        return this.formGroup?.get(key) as FormControl;
    }

    public isInvalid(question: AddressSubquestion): boolean {
        return this.getControl(question.key)?.invalid;
    }

    public isTouched(question: AddressSubquestion): boolean {
        return this.getControl(question.key)?.touched;
    }

    public getErrorMessage(question: AddressSubquestion): string {
        return this.getControl(question.key).errors?.['message'] || question.defaultErrorMessage;
    }
}
