import { Injectable } from '@angular/core';
import { EnergySourceInformation, ProjectResponseGetById } from '@nx-customer-apps/api-planning-projects';
import { EnergyConsumptionFormValue, EnergyConsumptionOptions } from '@nx-customer-apps/shared/interfaces';
import { Observable, filter, map, merge, withLatestFrom } from 'rxjs';
import { ProjectStore } from '../../state/project/project.store';
import { HeatPumpIntermediaryStore } from '../../state/heat-pump-intermediary/heat-pump-intermediary.store';
import { HeatloadFormOptionsService, HeatloadFormService } from '../../heatload/services';
import { BuildingType, EnergySource, FormName, HeatLoadCalculationMethod, ProjectType } from '@nx-customer-apps/shared/enums';
import { TranslateService } from '@ngx-translate/core';
import { HeatPumpIntermediaryPrefillService } from './heat-pump-intermediary-prefill.service';
import { Question } from '@nx-customer-apps/shared/utils';
import { HeatloadPrefillMapper, ProjectsService } from '../../services';
import { AccordionService } from '../../shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class HeatPumpIntermediaryEnergyConsumptionService implements AccordionService {
    constructor(
        private projectStore: ProjectStore,
        private projectsService: ProjectsService,
        private heatPumpIntermediaryStore: HeatPumpIntermediaryStore,
        private formService: HeatloadFormService,
        private formOptionsService: HeatloadFormOptionsService,
        private translate: TranslateService,
        private prefillMapper: HeatloadPrefillMapper,
        private prefillService: HeatPumpIntermediaryPrefillService
    ) {}

    public questions$: Observable<Question[] | null> = merge(
        this.qustionsForRenovationProject$(),
        this.qustionsForNewConstructionProject$(),
        this.qustionsForNewConstructionProjectCalculatedByLivingArea$()
    );

    public getSuccessMessage(formValue: EnergyConsumptionFormValue, selectedEnergySource: EnergySourceInformation | undefined): string {
        const nonHeatingEnergyUnit = this.translate.instant('MEASUREMENT_UNITS.KWH');
        const options = {
            nonHeatingEnergy: formValue.nonHeatingElectricityPerYear,
            nonHeatingEnergyUnit
        };

        if (!selectedEnergySource) {
            return this.translate.instant('HEATLOAD.ENERGY_CONSUMPTION.NON_HEATING_ENERGY.SUCCESS_MESSAGE', options);
        }

        const keySuffix = selectedEnergySource.unit.toUpperCase();
        const requiredEnergyUnit = this.translate.instant(`MEASUREMENT_UNITS.${keySuffix}`);
        const requiredEnergyOptions = {
            ...options,
            requiredEnergy: formValue.requiredEnergyPerYear,
            requiredEnergyUnit
        };
        return this.translate.instant('HEATLOAD.ENERGY_CONSUMPTION.SUCCESS_MESSAGE', requiredEnergyOptions);
    }

    public isPrefillNeeded(): boolean {
        return !this.prefillService.isPrefilled(FormName.EnergyConsumption);
    }

    public afterPrefill(): void {
        this.prefillService.setAsPrefilled(FormName.EnergyConsumption);
    }

    private qustionsForRenovationProject$() {
        return this.heatPumpIntermediaryStore.heatGeneratorDetails$.pipe(
            withLatestFrom(
                this.heatPumpIntermediaryStore.selectedHeatingEnergySource$,
                this.projectStore.project$,
                this.heatPumpIntermediaryStore.cache$,
                (heatGeneratorDetails, energySource, project, cache) => ({
                    heatGeneratorDetails,
                    energySource,
                    project,
                    cache
                })
            ),
            filter(({ project }) => this.projectsService.isRenovation(project!.projectType as ProjectType)),
            map(state => {
                if (!state.heatGeneratorDetails) {
                    return null;
                }

                const cachePrefill = this.prefillMapper.stateToEnergyConsumptionPrefill(state.cache?.energyConsumption);
                const projectPrefill = this.prefillMapper.projectToEnergyConsumptionPrefill(state.project);
                const options = this.formOptionsService.getEnergyConsumptionFormOptions({
                    selectedSource: state.energySource!.selectedEnergySource.type as EnergySource,
                    selectedUnit: state.energySource!.selectedEnergySource.unit,
                    buildingType: state.project!.building.buildingType as BuildingType,
                    prefill: cachePrefill || projectPrefill
                });

                return this.formService.getEnergyConsumptionQuestions(options as EnergyConsumptionOptions);
            })
        );
    }

    private qustionsForNewConstructionProject$() {
        return this.heatPumpIntermediaryStore.livingArea$.pipe(
            withLatestFrom(this.projectStore.project$, (livingArea, project) => ({ livingArea, project })),
            filter(
                ({ project }) =>
                    this.projectsService.isNewConstruction(project!.projectType as ProjectType) &&
                    !this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.LivingSpace)
            ),
            map(state => {
                if (!state.livingArea) {
                    return null;
                }

                return this.returnQuestionsForNewConstruction(state.project!);
            })
        );
    }
    private qustionsForNewConstructionProjectCalculatedByLivingArea$() {
        return this.projectStore.project$.pipe(
            filter(
                project =>
                    this.projectsService.isNewConstruction(project!.projectType as ProjectType) &&
                    this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.LivingSpace)
            ),
            map(project => this.returnQuestionsForNewConstruction(project!))
        );
    }

    private returnQuestionsForNewConstruction(project: ProjectResponseGetById): Question[] {
        const projectPrefill = this.prefillMapper.projectToEnergyConsumptionPrefill(project);
        const options = this.formOptionsService.getEnergyConsumptionFormOptions({
            buildingType: project?.building.buildingType as BuildingType,
            prefill: projectPrefill
        });

        return this.formService.getEnergyConsumptionQuestions(options as EnergyConsumptionOptions);
    }

    public isExpanded$ = this.heatPumpIntermediaryStore.sameValueSubmited$.pipe(
        withLatestFrom(this.projectStore.project$, (form, project) => ({ form, project })),
        map(({ form, project }) => {
            const afterHeatGeneratorDetailsForm =
                form?.formName === FormName.HeatGeneratorDetails && this.projectsService.isRenovation(project!.projectType as ProjectType);
            const afterLivingAreaForm =
                form?.formName === FormName.LivingArea &&
                this.projectsService.isNewConstruction(project!.projectType as ProjectType) &&
                !this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.LivingSpace);
            return afterHeatGeneratorDetailsForm || afterLivingAreaForm;
        })
    );
}
