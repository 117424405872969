import { ControlTypes } from '@nx-customer-apps/shared/enums';
import { Mask, SeachDropdownQuestionOptions, SearchDropdownItem } from '@nx-customer-apps/shared/interfaces';
import { QuestionBase } from './question-base.model';
import { Observable } from 'rxjs';

export class SearchDropdown extends QuestionBase<string> {
    override controlType = ControlTypes.SearchDropdown;
    public mask: Mask;
    public optionsUpdater: (value: string) => Observable<SearchDropdownItem[]>;
    public options: SearchDropdownItem[];
    public dropdownPanelClass: string;
    public conditionalHint: string;
    /**
     * Used to inform about no results found.
     */
    readonly defaultOptions = [{ key: 'COMMON.NO_RESULTS_FOUND', value: null }];
    constructor(options: SeachDropdownQuestionOptions) {
        super(options);
        this.mask = SearchDropdown.setMask(options.mask);
        this.options = options.options?.length ? options.options : this.defaultOptions;
        this.dropdownPanelClass = options.dropdownPanelClass || '';
        this.conditionalHint = options.conditionalHint || '';
        this.optionsUpdater = options.optionsUpdater;
    }

    static setMask(mask: Mask = { pattern: '' } as Mask): Mask {
        return {
            separatorLimit: '',
            ...mask
        };
    }
}
