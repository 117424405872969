import { Pipe, PipeTransform } from '@angular/core';
import { EnergySource, EnergySourcesIcons } from '@nx-customer-apps/shared/enums';

@Pipe({
    name: 'toIcon'
})
export class EnergySourceToIconPipe implements PipeTransform {
    public transform(value: EnergySource | string | undefined): string {
        return EnergySourcesIcons[value as keyof typeof EnergySourcesIcons];
    }
}
