import { Pipe, PipeTransform } from '@angular/core';
import { ProjectStatus } from '@nx-customer-apps/shared/enums';
import { isReadOnly } from '@nx-customer-apps/shared/utils';

@Pipe({
    name: 'isReadOnly'
})
export class IsReadOnlyPipe implements PipeTransform {
    transform(status: ProjectStatus): boolean {
        return isReadOnly(status);
    }
}
