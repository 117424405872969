<vp-dialog-header [title]="data.title" [titleElement]="data.titleElement" [context]="data.context" (close)="onClose()"></vp-dialog-header>
<mat-dialog-content *ngIf="data.content" class="vp-pre-line" [ngClass]="data.title || data.titleElement ? 'py-0' : 'pb-0'">
    {{ data.content }}
</mat-dialog-content>
<mat-dialog-content *ngIf="data.contentElement" [ngClass]="data.title || data.titleElement ? 'py-0' : 'pb-0'" class="mat-body-large">
    <ng-container *ngTemplateOutlet="data.contentElement; context: { $implicit: data.context }"></ng-container>
</mat-dialog-content>
<mat-dialog-content *ngIf="data.formElement" [ngClass]="data.title || data.titleElement ? 'py-0' : 'pb-0'">
    <form [formGroup]="form">
        <ng-container *ngTemplateOutlet="data.formElement; context: { $implicit: { form, context: data.context } }"></ng-container>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end align-items-center vp-gap-8 py-3 px-4">
    <button
        mat-stroked-button
        class="ca-button ca-button-m"
        (click)="onCancel()"
        data-testid="confirm-dialog-cancel-btn"
        id="pop-up-cancel"
    >
        {{ data.cancelBtn }}
    </button>
    <button
        mat-flat-button
        class="ca-button ca-button-m"
        [disabled]="data.formElement && form.invalid"
        (click)="onConfirm()"
        data-testid="confirm-dialog-confirm-btn"
        id="pop-up-confirm"
    >
        {{ data.confirmBtn }}
    </button>
</mat-dialog-actions>
