import { EnergySourceInformation, HeatGeneratorInformation } from '@nx-customer-apps/api-planning-projects';
import { EnergySource, HeatGeneratorType } from '@nx-customer-apps/shared/enums';

export const getSelectedSourceFromGenerators = (
    generators: HeatGeneratorInformation[],
    generatorType: HeatGeneratorType,
    energySourceType?: EnergySource
): EnergySourceInformation => {
    const generator = generators.find(item => item.heatGeneratorType === generatorType) as HeatGeneratorInformation;
    const isManySources = generator.energySources.length > 1;
    return isManySources ? generator.energySources.find(source => source.type === energySourceType)! : generator.energySources[0];
};
