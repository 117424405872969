import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { OnboardingService } from '../../../services';
import { Observable } from 'rxjs';
import { OnboardingItem } from '@nx-customer-apps/shared/interfaces';

@Component({
    selector: 'vp-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrl: './onboarding.component.scss'
})
export class OnboardingComponent implements OnInit {
    public selectedSlideIndex: number = 0;
    public FAQUrl$: Observable<string>;
    public allSlides: OnboardingItem[];
    constructor(public dialogRef: MatDialogRef<OnboardingComponent>, private onboarding: OnboardingService) {}

    public ngOnInit() {
        this.setFAQUrl();
        this.setSlides();
    }

    public readonly carouselOptions: OwlOptions = {
        touchDrag: true,
        dots: true,
        navSpeed: 700,
        responsive: {
            0: {
                items: 1
            }
        },
        nav: false
    };

    // TODO: Add unit tests
    public onClose(): void {
        this.dialogRef.close({ close: true });
    }

    public setSelectedSlide(change: SlidesOutputData) {
        this.selectedSlideIndex = change.startPosition as number;
    }

    public setFAQUrl() {
        this.FAQUrl$ = this.onboarding.getFAQUrl$();
    }

    public setSlides() {
        this.allSlides = this.onboarding.getSlides();
    }

    public isFirstSlide() {
        return this.selectedSlideIndex === 0;
    }

    public isLastSlide() {
        return this.selectedSlideIndex === this.allSlides.length - 1;
    }
}
