import { Injectable } from '@angular/core';
import { distinctUntilChanged, filter, first, map, Observable, withLatestFrom } from 'rxjs';
import { GEGStore } from '../../state/geg/geg.store';
import { GEGFormOptionsService } from './geg-form-options.service';
import { HeatloadFormService } from '../../heatload/services';
import { FormName, GEGSystemType, SuccessMessages } from '@nx-customer-apps/shared/enums';
import { getSelectedSourceFromGenerators } from '../../shared/utils';
import { HeatGeneratorInformation } from '@nx-customer-apps/api-planning-projects';
import { HeatGeneratorFormValue } from '@nx-customer-apps/shared/interfaces';
import { GegPrefillService } from './geg-prefill.service';
import { LodashService } from '@nx-customer-apps/shared/services';

@Injectable({
    providedIn: 'root'
})
export class GegCurrentSystemEnergySourceService {
    constructor(
        private formOptionsService: GEGFormOptionsService,
        private prefillService: GegPrefillService,
        private formService: HeatloadFormService,
        private gegStore: GEGStore
    ) {}

    public questions$ = this.gegStore.heatGenerators$.pipe(
        distinctUntilChanged((prev, curr) => LodashService.isEqual(prev, curr)),
        withLatestFrom(this.gegStore.currentSystemEnergySource$, (heatGenerators, energySource) => ({
            heatGenerators,
            energySource
        })),
        map(state => {
            if (!state.heatGenerators) {
                return null;
            }
            const options = this.formOptionsService.getEnergySourceFormOptions({
                prefill: state.energySource,
                heatGenerators: state.heatGenerators
            });
            return this.formService.getEnergySourceQuestions(options);
        })
    );

    public getSuccessMessage(generators: HeatGeneratorInformation[], formValue: HeatGeneratorFormValue): string {
        const energySource = getSelectedSourceFromGenerators(generators, formValue.heatGenerator, formValue.specificEnergySource);
        return `${SuccessMessages.HeatingEnergySource}.${energySource.type}`;
    }

    public isPrefillNeeded(): boolean {
        return !this.prefillService.isPrefilled(FormName.EnergySource, GEGSystemType.Current);
    }

    public afterPrefill(): void {
        this.prefillService.setAsPrefilled(FormName.EnergySource, GEGSystemType.Current);
    }

    public isExpanded$ = this.isPrefillCompleted$();

    private isPrefillCompleted$(): Observable<boolean> {
        return this.prefillService.state$.pipe(
            map(prefillState => prefillState[GEGSystemType.Current][FormName.HeatGeneratorDetails].isPrefilled),
            filter(Boolean),
            first()
        );
    }
}
