<div class="d-flex flex-column align-items-center vp-gap-24 my-6 p-6">
    <div>
        <img src="/assets/images/error-404.png" />
    </div>
    <div data-testid="error-page-title">
        <strong class="mat-display-large mb-0">{{ 'COMMON.ERRORS.404.TITLE' | translate }}</strong>
    </div>
    <div class="d-flex flex-column align-items-center vp-gap-24">
        <div>
            {{ 'COMMON.ERRORS.404.CONTENT' | translate }}
        </div>
        <div class="d-flex justify-content-center mt-4">
            <button mat-stroked-button class="ca-button mt-auto" routerLink="/projects" data-testid="error-page-link">
                <ca-icon icon="arrow-left" class="ca-icon-s"></ca-icon>
                <span>{{ 'NAVBAR.PROJECTS' | translate }}</span>
            </button>
        </div>
    </div>
</div>
