<div
    *ngIf="globalSpinnerService.state | async as state"
    class="ca-spinner global-spinner flex-column justify-content-center align-items-center"
    [class.d-flex]="state.isShown"
    [attr.data-testid]="state.isShown ? 'global-spinner' : null"
>
    <div class="global-spinner-content d-flex flex-column justify-content-center align-items-center">
        <mat-spinner color="accent"></mat-spinner>
        <div class="mt-4 mat-display-large mb-0" data-testid="global-spinner-text">{{ state.loadingText | translate }}</div>
        <div *ngIf="state.loadingSubtext" data-testid="global-spinner-subtext">{{ state.loadingSubtext | translate }}</div>
    </div>
</div>
