import { ActionCreator, createAction, props } from '@ngrx/store';
import {
    CreateProjectRequestPost,
    FinalizeProjectResponse,
    HeatLoadSelectRequestPost,
    PartialHeatLoadsResponse,
    ProjectResponseGetById,
    UpdateProjectRequestPartial
} from '@nx-customer-apps/api-planning-projects';
import { HeatLoadCalculationMethod, ReportConfigurationSection } from '@nx-customer-apps/shared/enums';
import { ReportConfiguration } from '@nx-customer-apps/shared/interfaces';

export namespace ProjectActions {
    export const getProject = createAction('[Get Project]', props<{ id: string }>());
    export const reloadProject = createAction(
        '[Relaod Project]',
        props<{
            id: string;
            successAction: ActionCreator;
            successActionOptions?: any;
        }>()
    );
    export const createProject = createAction('[Create Project]', props<{ request: CreateProjectRequestPost }>());
    export const beforeUpdateProject = createAction(
        '[Before Update Project]',
        props<{ id: string; request: UpdateProjectRequestPartial; redirectUrl?: string }>()
    );
    export const updateProject = createAction(
        '[Update Project]',
        props<{
            id: string;
            request: UpdateProjectRequestPartial;
            successAction: ActionCreator;
            successActionOptions?: any;
        }>()
    );
    export const getProjectSuccess = createAction(
        '[Get Project Success]',
        props<{ project: ProjectResponseGetById; redirectUrl?: string }>()
    );
    export const saveHeatload = createAction(
        '[Save Heatload]',
        props<{ projectPatch: UpdateProjectRequestPartial; redirectUrl: string }>()
    );
    export const saveHeatloadSuccess = createAction(
        '[Save Heatload Success]',
        props<{ project: ProjectResponseGetById; redirectUrl: string }>()
    );
    export const recalculateHeatloads = createAction(
        '[Recalculate Heatloads]',
        props<{ id: string; request: UpdateProjectRequestPartial; redirectUrl?: string }>()
    );
    export const recalculateHeatloadsSuccess = createAction(
        '[Recalculate Heatloads Success]',
        props<{ id: string; request: UpdateProjectRequestPartial; redirectUrl?: string; heatloads: PartialHeatLoadsResponse[] }>()
    );
    export const saveHeatPumpIntermediary = createAction(
        '[Save Heat Pump Intermediary]',
        props<{ request: UpdateProjectRequestPartial; variantId: string }>()
    );
    export const saveHeatPumpIntermediarySuccess = createAction(
        '[Save Heat Pump Intermediary Success]',
        props<{ project: ProjectResponseGetById; variantId: string }>()
    );
    export const migrationVerificationSuccess = createAction(
        '[Migration Verification Success]',
        props<{ project: ProjectResponseGetById; callback?: () => void }>()
    );
    export const savePhotovoltaicIntermediary = createAction(
        '[Save Photovoltaic Intermediary]',
        props<{ variantId: string; request: UpdateProjectRequestPartial }>()
    );
    export const savePhotovoltaicIntermediarySuccess = createAction(
        '[Save Photovoltaic Intermediary Success]',
        props<{ variantId: string }>()
    );
    export const deleteHeatload = createAction('[Delete Heatload]', props<{ method: HeatLoadCalculationMethod }>());
    export const deleteHeatloadSuccess = createAction('[Delete Heatload Success]', props<{ project: ProjectResponseGetById }>());
    export const selectHeatload = createAction('[Select Heatload]', props<{ request: HeatLoadSelectRequestPost }>());
    export const selectHeatloadSuccess = createAction('[Select Heatload Success]', props<{ project: ProjectResponseGetById }>());
    export const resetProject = createAction('[Reset Project]', props<{ projectId: string }>());
    export const addressCollision = createAction('[Project Address Collision]');
    export const closeProject = createAction('[Close Project]', props<{ projectId: string }>());
    export const closeProjectSuccess = createAction('[Close Project Success]', props<{ response: FinalizeProjectResponse }>());
    export const completeProject = createAction('[Complete Project]', props<{ projectId: string }>());
    export const completeProjectSuccess = createAction('[Complete Project Success]', props<{ response: FinalizeProjectResponse }>());
    export const setReportConfiguration = createAction(
        '[Set Report Configuration]',
        props<{ reportConfiguration: ReportConfiguration[] }>()
    );

    export const uploadProjectReportSection = createAction(
        '[Upload Project Report Section]',
        props<{ body: string; projectId: string; section: ReportConfigurationSection; applicationVersion: string }>()
    );
    export const uploadProjectReportSectionSuccess = createAction('[Upload Project Report Section Success]');
}
