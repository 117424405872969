import { ResponseSummaryActionType } from '@nx-customer-apps/shared/enums';
import { EnumDictionary } from '../types';

export const ResponseSummaryActionDictionary: Partial<EnumDictionary<ResponseSummaryActionType, string>> = {
    [ResponseSummaryActionType.HeatLoadCalculationAutoSelected]: 'SUMMARY_ACTIONS.ACTION_TYPE.HEAT_LOAD_CALCULATION_AUTO_SELECTED',
    [ResponseSummaryActionType.HeatLoadFirstCalculationAutoSelected]:
        'SUMMARY_ACTIONS.ACTION_TYPE.HEAT_LOAD_FIRST_CALCULATION_AUTO_SELECTED',
    [ResponseSummaryActionType.HeatLoadCalculationSpecificParamsAppied]:
        'SUMMARY_ACTIONS.ACTION_TYPE.HEAT_LOAD_CALCULATION_SPECIFIC_PARAMS_APPLIED',
    [ResponseSummaryActionType.PlanningsRemoved]: 'SUMMARY_ACTIONS.ACTION_TYPE.PLANNINGS_REMOVED',
    [ResponseSummaryActionType.RecheckNeededSet]: 'SUMMARY_ACTIONS.ACTION_TYPE.RECHECK_NEEDED_SET',
    [ResponseSummaryActionType.SelectedCalculationMethodRecalculated]:
        'SUMMARY_ACTIONS.ACTION_TYPE.SELECTED_CALCULATION_METHOD_RECALCULATED',
    [ResponseSummaryActionType.CalculationMethodRecalculated]: 'SUMMARY_ACTIONS.ACTION_TYPE.CALCULATION_METHOD_RECALCULATED'
};
