import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutePaths } from '@nx-customer-apps/shared/enums';
import { Error403Component } from './core/components/errors/403/error-403.component';
import { Error404Component } from './core/components/errors/404/error-404.component';
import { NoSalesForceAccessComponent } from './core/components/errors/no-sales-force-access/no-sales-force-access.component';
import { authGuard, devGuard, isEditableGuard, isProjectLockedGuard } from './guards';
import { isCorrectLocaleGuard } from './guards/is-correct-locale.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
        pathMatch: 'full',
        canActivate: [authGuard]
    },
    {
        path: RoutePaths.Projects,
        loadChildren: () => import('./projects/projects-list/project-lists.module').then(m => m.ProjectListsModule),
        canActivate: [authGuard]
    },
    {
        path: RoutePaths.NewProject,
        loadChildren: () => import('./projects/new-project-module/new-project.module').then(m => m.NewProjectModule),
        canActivate: [authGuard]
    },
    {
        path: RoutePaths.VerifyBasicInformation,
        loadChildren: () =>
            import('./projects/verify-basic-information-module/verify-basic-information.module').then(m => m.VerifyBasicInformationModule),
        canActivate: [authGuard, isProjectLockedGuard, isCorrectLocaleGuard, isEditableGuard]
    },
    {
        path: RoutePaths.EditProject,
        loadChildren: () => import('./projects/edit-project-module/edit-project.module').then(m => m.EditProjectModule),
        canActivate: [authGuard, isProjectLockedGuard, isCorrectLocaleGuard, isEditableGuard]
    },
    {
        path: RoutePaths.EditEnergyPrices,
        loadChildren: () => import('./projects/edit-energy-prices-module/edit-energy-prices.module').then(m => m.EditEnergyPricesModule),
        canActivate: [authGuard, isProjectLockedGuard, isCorrectLocaleGuard, isEditableGuard]
    },
    {
        path: RoutePaths.VerifyEnergyPrices,
        loadChildren: () =>
            import('./projects/verify-energy-prices-module/verify-energy-prices.module').then(m => m.VerifyEnergyPricesModule),
        canActivate: [authGuard, isProjectLockedGuard, isCorrectLocaleGuard, isEditableGuard]
    },
    {
        path: RoutePaths.ProjectDetails,
        loadChildren: () => import('./projects/project-details/project-details.module').then(m => m.ProjectDetailsModule),
        canActivate: [authGuard, isCorrectLocaleGuard]
    },
    {
        path: RoutePaths.CreateReport,
        loadChildren: () => import('./create-report/create-report.module').then(m => m.CreateReportModule),
        canActivate: [authGuard, isProjectLockedGuard, isCorrectLocaleGuard, isEditableGuard]
    },
    {
        path: RoutePaths.PdfReport,
        loadChildren: () => import('./pdf-report/pdf-report.module').then(m => m.PDFReportModule),
        canActivate: [authGuard, isCorrectLocaleGuard, devGuard]
    },
    {
        path: RoutePaths.Heatload,
        loadChildren: () => import('./heatload/heatload.module').then(m => m.HeatloadModule),
        canActivate: [authGuard, isProjectLockedGuard, isCorrectLocaleGuard, isEditableGuard]
    },
    {
        path: RoutePaths.HeatPumpIntermediary,
        loadChildren: () => import('./heat-pump-intermediary/heat-pump-intermediary.module').then(m => m.HeatPumpIntermediaryModule),
        canActivate: [authGuard, isProjectLockedGuard, isCorrectLocaleGuard, isEditableGuard]
    },
    {
        path: RoutePaths.GEG,
        loadChildren: () => import('./geg/geg.module').then(m => m.GEGModule),
        canActivate: [authGuard, isProjectLockedGuard, isCorrectLocaleGuard, isEditableGuard]
    },
    { path: RoutePaths.Error404, component: Error404Component },
    { path: RoutePaths.Error403, component: Error403Component },
    { path: RoutePaths.ErrorNoAccess, component: NoSalesForceAccessComponent },
    { path: '**', redirectTo: RoutePaths.Error404, pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
