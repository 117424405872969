import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Question, QuestionBase, QuestionGroupBase } from '@nx-customer-apps/shared/utils';
import { LodashService } from './lodash.service';
import { Observable, Subject, filter } from 'rxjs';
import { FormKeys } from '@nx-customer-apps/shared/enums';

@Injectable({
    providedIn: 'root'
})
export class QuestionControlService {
    private controlFormKeysToUnsubscribe$: Subject<FormKeys[]> = new Subject();

    public toFormGroup(questions: Question[]): FormGroup {
        const group: any = {};
        questions.sort((a, b) => a.order - b.order);
        questions.forEach(question => {
            if (question.hasOwnProperty('groupName')) {
                group[(question as QuestionGroupBase<any>).groupName] = this.toFormGroup((question as QuestionGroupBase<any>).questions);
                group[(question as QuestionGroupBase<any>).groupName].setValidators(question.validators);
                return;
            }
            group[(question as QuestionBase<any>).key] = new FormControl(
                {
                    value: LodashService.isNil((question as QuestionBase<any>).value) ? null : (question as QuestionBase<any>).value,
                    disabled: (question as QuestionBase<any>).disabled
                },
                question.validators
            );
        });
        return new FormGroup(group);
    }

    public getQuestionFormKey(question: Question): string {
        return (question as QuestionBase<any>).key || (question as QuestionGroupBase<any>).groupName;
    }

    public toggleControlState(value: boolean, control: FormControl): void {
        if (!control) {
            return;
        }
        const isNeededToDisable = !value && control.enabled;
        if (isNeededToDisable) {
            control.reset();
            control.disable();
            return;
        }
        const isNeededToEnable = value && control.disabled;
        if (isNeededToEnable) {
            control.enable();
            control.markAsTouched();
        }
    }

    /**
     * Can be used to mark the moment to unsubscribe from control's valuChanges observable. For example when the control is removed form form e.t.c.
     */
    public emitControlFormKeysToUnsubsribe(formKeys: FormKeys[]) {
        this.controlFormKeysToUnsubscribe$.next(formKeys);
    }

    /**
     * Return the stream if control with assigned formKey value was included in the last controlFormKeysToUnsubscribe$ emition.
     */
    public unsubscribeControl$(formKey: FormKeys): Observable<FormKeys[]> {
        return this.controlFormKeysToUnsubscribe$.pipe(filter(keys => keys.includes(formKey)));
    }
}
