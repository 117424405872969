import { Injectable } from '@angular/core';
import { BuildingType, FormKeys, ProjectType } from '@nx-customer-apps/shared/enums';
import { BuildingFormValue, DeviceFormValue, FormOptions, QuestionOverride, SubsidyEligibility } from '@nx-customer-apps/shared/interfaces';

import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@nx-customer-apps/shared/services';

@Injectable({
    providedIn: 'root'
})
export class HeatPumpIntermediaryFormOptionsService {
    constructor(private translateService: TranslateService, private snackbarService: SnackbarService) {}

    public getDeviceFormOptions(options?: { prefill?: DeviceFormValue }): FormOptions {
        try {
            return {
                questions: {
                    [FormKeys.IsOldDeviceUsed]: { value: options?.prefill?.[FormKeys.IsOldDeviceUsed] }
                }
            };
        } catch (error) {
            this.showError();
            throw Error(`Project data is corrupt. Cannot prefill the state. ${error}`);
        }
    }

    public getSubsidyEligibilityFormOptions(options?: {
        prefill?: SubsidyEligibility;
        projectType: ProjectType;
        buildingType: BuildingType;
    }): FormOptions {
        const { prefill, projectType, buildingType } = options || {};

        if (!prefill) {
            return { projectType, buildingType };
        }
        try {
            const questions: { [key: string]: QuestionOverride } = {};
            if (buildingType === BuildingType.SingleFamilyHouse) {
                questions[FormKeys.IsIncomeLowForSubsidy] = { value: prefill?.[FormKeys.IsIncomeLowForSubsidy] };
                questions[FormKeys.IsOwnerOfTheBuilding] = { value: prefill?.[FormKeys.IsOwnerOfTheBuilding] };
                questions[FormKeys.IsOwnerMainResidence] = { value: prefill?.[FormKeys.IsOwnerMainResidence] };

                if (projectType === ProjectType.Renovation) {
                    questions[FormKeys.IsOldHeatingSystemDefective] = { value: prefill?.[FormKeys.IsOldHeatingSystemDefective] };
                }
            } else if (buildingType === BuildingType.MultiFamilyHouse) {
                questions[FormKeys.NumberOfResidentialUnits] = { value: prefill?.[FormKeys.NumberOfResidentialUnits] };
            }

            return {
                questions,
                projectType,
                buildingType
            };
        } catch (error) {
            this.showError();
            throw Error(`Project data is corrupt. Cannot prefill the state. ${error}`);
        }
    }

    public getBuildingFormOptions(options?: { prefill?: BuildingFormValue }): FormOptions {
        const { prefill } = options || {};
        try {
            return {
                questions: {
                    [FormKeys.BuildingConstructionYear]: { value: prefill?.[FormKeys.BuildingConstructionYear]?.toString() }
                }
            };
        } catch (error) {
            this.showError();
            throw Error(`Project data is corrupt. Cannot prefill the state. ${error}`);
        }
    }

    private showError(): void {
        const message = this.translateService.instant('COMMON.ERRORS.DATA_CORRUPT');
        const action = this.translateService.instant('COMMON.BUTTONS.GOT_IT');
        this.snackbarService.showInfo(message, {
            duration: 8000,
            action
        });
    }
}
