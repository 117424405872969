import { AbstractControl, ValidationErrors } from '@angular/forms';
import { SearchDropdownItem } from '@nx-customer-apps/shared/interfaces';

export const searchDropdownOptionsValidator = (options: SearchDropdownItem[], message: string) => {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }
        const match = options.find(option => option.key === control.value);
        return match ? null : { message };
    };
};
