import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ControlTypes } from '@nx-customer-apps/shared/enums';
import { CustomAnswerRadio, TypesHTMLHelper } from '@nx-customer-apps/shared/utils';
import { slideDown } from '../../animations';

@Component({
    selector: 'vp-custom-answer-radio',
    templateUrl: './custom-answer-radio.component.html',
    styleUrls: ['./custom-answer-radio.component.scss'],
    animations: [slideDown]
})
export class CustomAnswerRadioComponent implements OnInit {
    anyRadioActive: boolean;
    @Input() question: CustomAnswerRadio;
    @Input() form: FormGroup;
    readonly CONTROL_TYPES: typeof ControlTypes = ControlTypes;
    readonly TYPES = TypesHTMLHelper;

    ngOnInit() {
        this.setRadio();
        this.setText();
    }

    public get formGroup(): AbstractControl {
        return this.form.get((this.question as CustomAnswerRadio).groupName) as AbstractControl;
    }

    public get isGroupInvalid(): boolean {
        return this.formGroup?.invalid;
    }

    public get isGroupTouched(): boolean {
        return !!this.isRadioTouched && !!this.isTextTouched;
    }

    public get groupErrorMessage(): string {
        const errorMessage = this.formGroup?.errors?.['message'];
        return errorMessage || (this.question as CustomAnswerRadio)?.defaultErrorMessage;
    }

    public get radio(): AbstractControl {
        return this.form.get(this.question.groupName)!.get(this.question.questions[0].key)!;
    }

    public get isRadioInvalid(): boolean {
        return this.radio.invalid;
    }

    public get isRadioTouched(): boolean {
        return this.radio.touched;
    }

    public get text(): AbstractControl {
        return this.form.get(this.question.groupName)!.get(this.question.questions[1].key)!;
    }

    public get isTextInvalid(): boolean {
        return this.text.invalid;
    }

    public get isTextTouched(): boolean {
        return this.text.touched;
    }

    public get textErrorMessage(): string {
        const errorMessage = this.text.errors?.['message'];
        return errorMessage || (this.question as CustomAnswerRadio)?.questions[1].defaultErrorMessage;
    }

    public onTextFieldFocus(): void {
        this.radio?.reset(null);
    }

    private setRadio(): void {
        this.radio?.valueChanges?.subscribe(value => {
            this.anyRadioActive = !!value;
            if (value) {
                this.text?.patchValue(null);
            }
        });
    }

    private setText(): void {
        this.text?.valueChanges?.subscribe(value => {
            if (value) {
                this.radio?.patchValue(null);
            }
        });
    }
}
