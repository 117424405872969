import { Injectable } from '@angular/core';
import { filter, Observable, first, map, switchMap, tap, take } from 'rxjs';
import { ApplicationId, FormKeys, ProductType, RouterLinks } from '@nx-customer-apps/shared/enums';
import { DuplicatePlanningOption, DuplicatePlanningOptionValue, ProductWithTemplateAttributes } from '@nx-customer-apps/shared/interfaces';
import {
    CreateVariantRequestPost,
    CreateVariantResponse,
    DeleteVariantResponse,
    DuplicatePlanningRequestPost,
    PlanningsControllerService,
    ProjectResponseGetById,
    SelectVariantResponse,
    UpdateVariantResponse,
    VariantGetById,
    VariantsControllerService
} from '@nx-customer-apps/api-planning-projects';
import { ProjectStore } from '../../../state/project/project.store';
import { ProjectsService } from '../../../services';
import { GlobalSpinnerService } from '../../../core';
import { ConfirmService } from '@nx-customer-apps/shared/ui';
import { Router } from '@angular/router';
import { defaultVariantNameKey } from '@nx-customer-apps/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { Validators } from '@angular/forms';
import { getOrder } from '../../../shared/utils';

@Injectable({
    providedIn: 'root'
})
export class VariantsService {
    constructor(
        private projectStore: ProjectStore,
        private projectsService: ProjectsService,
        private variantsControllerService: VariantsControllerService,
        private planningControllerService: PlanningsControllerService,
        private globalSpinnerService: GlobalSpinnerService,
        private confirmService: ConfirmService,
        private translate: TranslateService,
        private router: Router
    ) {}

    public getVariant(variantId: string): Observable<VariantGetById | undefined> {
        return this.projectStore.project$.pipe(
            filter(Boolean),
            first(),
            map(project => project.variants?.find(variant => variant.id === variantId))
        );
    }

    public getVariantNumber(variantId: string): Observable<number> {
        return this.projectStore.project$.pipe(
            filter(Boolean),
            first(),
            map(project => {
                const variantItem = project.variants?.find(variant => variant.id === variantId) as VariantGetById;
                return project.variants?.indexOf(variantItem);
            }),
            map(variantIndex => variantIndex + 1)
        );
    }

    public getProductsFromVariant(variant: VariantGetById, projectId: string): ProductWithTemplateAttributes[] | undefined {
        const products = variant.plannings
            ?.map(planning =>
                planning?.products
                    ?.filter(product => product.productType !== ProductType.Other)
                    .map(product => this.projectsService.extendProduct(product, planning.applicationId as ApplicationId, projectId))
            )
            .flat(1);
        return products?.length ? (products as ProductWithTemplateAttributes[]) : undefined;
    }

    public addVariant(options: { projectId: string; variants: VariantGetById[] }): Observable<CreateVariantResponse> {
        this.globalSpinnerService.next({
            isShown: true,
            loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
            loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
        });
        const { projectId, variants } = options;

        const order = getOrder(variants);
        const variantName = this.translate.instant(defaultVariantNameKey);

        const request = { body: { projectId, name: variantName, isSelected: false, order } };
        return this.variantsControllerService.variantsControllerCreateVariant(request).pipe(
            tap(() => {
                this.projectStore.reloadProject(projectId);
            })
        );
    }

    public deleteVariant(options: { variantId: string; projectId: string; dialogUUID: string }): Observable<DeleteVariantResponse> {
        const { variantId, projectId, dialogUUID } = options;

        return this.confirmService.getDialog(dialogUUID + variantId).pipe(
            filter(output => !!output?.confirm),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            switchMap(() => {
                const request = { id: variantId };
                return this.variantsControllerService.variantsControllerDeleteVariant(request).pipe(
                    tap(() => {
                        this.globalSpinnerService.next({
                            isShown: true,
                            loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                            loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                        });
                        this.projectStore.reloadProject(projectId);
                    })
                );
            })
        );
    }

    public selectVariant(options: { variantId: string; projectId: string }): Observable<SelectVariantResponse> {
        this.globalSpinnerService.next({
            isShown: true,
            loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
            loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
        });

        const { variantId, projectId } = options;
        const request = { body: { variantId, projectId } };
        return this.variantsControllerService.variantsControllerSelectVariant(request).pipe(
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
                this.projectStore.reloadProject(projectId);
            })
        );
    }

    public duplicateVariant(options: { variantId: string; projectId: string; dialogUUID: string }): Observable<UpdateVariantResponse> {
        const { variantId, projectId, dialogUUID } = options;
        return this.confirmService.getDialog(dialogUUID + variantId).pipe(
            filter(output => !!output?.confirm),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            switchMap(() => {
                const variantName = this.translate.instant(defaultVariantNameKey);
                const request = { id: variantId, body: { name: variantName } };
                return this.variantsControllerService.variantsControllerDuplicateVariant(request).pipe(
                    tap(() => {
                        this.globalSpinnerService.next({
                            isShown: true,
                            loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                            loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                        });
                        this.projectStore.reloadProject(projectId);
                    })
                );
            })
        );
    }

    public deletePlanning(options: { planningId: string; projectId: string; dialogUUID: string }): Observable<UpdateVariantResponse> {
        const { planningId, projectId, dialogUUID } = options;
        return this.confirmService.getDialog(dialogUUID + planningId).pipe(
            filter(output => !!output?.confirm),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            }),
            switchMap(() => {
                const request = { id: planningId };
                return this.planningControllerService.planningsControllerDeletePlanning(request).pipe(
                    tap(() => {
                        this.globalSpinnerService.next({
                            isShown: true,
                            loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                            loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                        });
                        this.projectStore.reloadProject(projectId);
                    })
                );
            })
        );
    }

    public duplicatePlanning(options: { planningId: string; projectId: string; dialogUUID: string }): Observable<UpdateVariantResponse> {
        const { planningId, projectId, dialogUUID } = options;
        const showSpinner = () =>
            setTimeout(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.UPDATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.UPDATING_PROJECT'
                });
            });

        return this.confirmService.getDialog(dialogUUID + planningId).pipe(
            filter(output => !!output?.confirm),
            tap(() => showSpinner()),
            switchMap(output => {
                const targetVariant: DuplicatePlanningOptionValue = output?.form[FormKeys.TargetVariant];

                const duplicatePlanning$ = (request: { id: string; body: DuplicatePlanningRequestPost }) => {
                    showSpinner();
                    return this.planningControllerService.planningsControllerDuplicatePlanning(request).pipe(
                        tap(() => {
                            showSpinner();
                            this.projectStore.reloadProject(projectId);
                        })
                    );
                };

                const createNewVariant$ = (request: { body: CreateVariantRequestPost }) => {
                    return this.variantsControllerService.variantsControllerCreateVariant(request).pipe(tap(() => showSpinner()));
                };

                const newVariantRequest = targetVariant.newVariantRequest;
                if (newVariantRequest) {
                    const request = {
                        body: { projectId, name: newVariantRequest.name, isSelected: false, order: newVariantRequest.order }
                    };
                    return createNewVariant$(request).pipe(
                        map((variantResponse: CreateVariantResponse) => ({
                            id: planningId,
                            body: { targetVariantId: variantResponse.id }
                        })),
                        switchMap(payload => duplicatePlanning$(payload))
                    );
                }

                const request = { id: planningId, body: { targetVariantId: targetVariant.id } };
                return duplicatePlanning$(request);
            })
        );
    }

    public navigateToPlanning(options: { applicationId: ApplicationId; projectId: string; variantId: string }): void {
        const { applicationId, projectId, variantId } = options;

        const navigateToPlanHeatPump = (project: ProjectResponseGetById) => {
            const calculations = this.projectsService.getHeatloads(project);
            if (!calculations?.length) {
                this.router.navigate([`${RouterLinks.Project}${projectId}${RouterLinks.Heatload}`], {
                    queryParams: { isRedirectionToHeatPump: 'true' }
                });
                return;
            }
            this.router.navigate([`${RouterLinks.Project}${projectId}/plan-heat-pump`], { queryParams: { variantId } });
        };

        const navigateToPhotovoltaicPlanner = () => {
            this.projectsService.handlePhotovoltaicPlannerAction(variantId);
        };

        const externalToolActionDictionary = {
            [ApplicationId.HeatPumpPlanner]: navigateToPlanHeatPump,
            [ApplicationId.PhotovoltaicPlanner]: navigateToPhotovoltaicPlanner
        };

        this.projectStore.project$
            .pipe(
                filter(Boolean),
                take(1),
                tap(project => {
                    const externalToolAction = externalToolActionDictionary[applicationId as keyof typeof externalToolActionDictionary];
                    externalToolAction(project);
                })
            )
            .subscribe();
    }

    public verifyPlanning(options: { applicationId: ApplicationId; projectId: string; variantId: string }): void {
        const { applicationId, projectId, variantId } = options;

        const verificationDictionary = {
            [ApplicationId.HeatPumpPlanner]: () =>
                this.router.navigate([`${RouterLinks.Project}${projectId}/plan-heat-pump`], { queryParams: { reconfigure: true } }),
            [ApplicationId.PhotovoltaicPlanner]: () => this.projectsService.handlePhotovoltaicPlannerAction(variantId)
        };

        const verify = verificationDictionary[applicationId as keyof typeof verificationDictionary];

        verify();
    }

    public getVariantDetailsLink(options: { variantId: string | undefined; projectId: string }): string {
        const { variantId, projectId } = options;
        if (!variantId) {
            return '';
        }
        return `${RouterLinks.Project}${projectId}${RouterLinks.Variants}/${variantId}`;
    }

    public getDuplicatePlanningFormOptions(options: DuplicatePlanningOption[] | undefined): { [key: string]: any[] } {
        const value = options && options.length > 1 ? '' : options![0]?.value;
        return { [FormKeys.TargetVariant]: [value, [Validators.required]] };
    }

    public getSelectedVariant(project: ProjectResponseGetById): VariantGetById {
        return project.variants.find(variant => variant.isSelected)!;
    }
}
